import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import dayjs from 'dayjs';
import './modal.css';
import Bell from '../../assets/icons/Bell-sm.svg';
import Select, { components } from 'react-select';
import { dropDownStyles } from '../../constants/styles';
import FormContext from '../../context/FormContext';
import { resolve } from '../../api/resolve';
import useAxios from '../../hooks/useAxios';
import DatePickerComponent from '../../shared/DatePickerComponent';

const customStyles = {
  content: {
    position: 'relative',
    top: '40%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '350px',
    maxWidth: '400px',
    minHeight: 220,
    overflow: 'visible',
    borderRadius: '11px',
  },
  overlay: {
    backgroundColor: 'rgba(64, 8, 53, 0.63)',
  },
};
const customDropDownStyles = {
  ...dropDownStyles,
  singleValue: (provided) => {
    return {
      ...provided,
      color: '#400835',
      fontWeight: 'bold',
      marginLeft: 20,
    };
  },
  input: (provided) => {
    return {
      ...provided,
      color: '#400835',
      marginLeft: 10,
      color: 'transparent',
    };
  },
  placeholder: (provided) => {
    return {
      ...provided,
      color: '#400835',
      fontSize: 16,
      fontWeight: 'bold',
      marginLeft: 20,
    };
  },
  menuList: (provided) => ({ ...provided }),
};

const options = [
  { value: 7, label: '1 week before' },
  { value: 30, label: '1 month before' },
  { value: 'Custom Date', label: 'Custom Date' },
];
Modal.setAppElement('#root');

const ValueContainer = ({ children, ...props }) => {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        {!!children && <img src={Bell} width={15} className='absolute ml-2' />}
        {children}
      </components.ValueContainer>
    )
  );
};
const AddReminderModal = ({
  modalIsOpen,
  setIsOpen,
  getContracts,
  isRenewalAbsent,
}) => {
  const api = useAxios();
  const { editFormData } = useContext(FormContext);
  const [selectedReminder, setSelectedReminder] = useState(7);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [showFeedback, setShowFeedback] = useState({
    isShown: false,
    message: '',
  });
  const addContractRemainder = async () => {
    const res = await resolve(
      api
        .patch(
          `/contract/retrieve_update/${editFormData.id}`,
          JSON.stringify({
            remainder_days:
              typeof selectedReminder === 'number' ? selectedReminder : null,
            remainder_date:
              typeof selectedReminder !== 'number' && startDate
                ? dayjs(startDate).format('YYYY-MM-DD')
                : null,
          }),
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then((res) => res.data)
    );
    if (res.data) {
      getContracts();
      setShowFeedback({
        isShown: true,
        message: 'Reminder added successfully.',
      });
    } else {
      setShowFeedback({
        isShown: true,
        message: 'Unable to add reminder. Try again',
      });
    }
  };
  useEffect(() => {
    if (editFormData) {
      setStartDate(
        editFormData.reminder_date ? new Date(editFormData.reminder_date) : ''
      );
      editFormData.reminder_date && setShowDatePicker(true);
    }
  }, [editFormData]);
  return (
    <div className=''>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        contentLabel='Example Modal'
        onAfterClose={() => {
          setShowFeedback({ ...showFeedback, isShown: false });
          setStartDate('');
          setShowDatePicker(false);
        }}
      >
        {!showFeedback.isShown ? (
          <>
            {' '}
            <h2 className='text-primary text-sm font-bold'>Reminder</h2>
            <Select
              options={
                !isRenewalAbsent
                  ? options
                  : [{ value: 'Custom Date', label: 'Custom Date' }]
              }
              placeholder='Select Reminder'
              styles={customDropDownStyles}
              onChange={(option) => {
                setShowDatePicker(false);
                if (option.value === 'Custom Date') {
                  setShowDatePicker(true);
                }
                setSelectedReminder(option.value);
              }}
              className='w-full text-primary rounded-lg my-1 mb-3'
              components={{ ValueContainer }}
              isSearchable={false}
              defaultValue={
                !isRenewalAbsent
                  ? startDate
                    ? options[2]
                    : (editFormData && editFormData.reminder_days === 7) ||
                      (editFormData && !editFormData.reminder_days)
                    ? options[0]
                    : options[1]
                  : null
              }
            />
            {showDatePicker && (
              <DatePickerComponent
                placeholderText='Select Date'
                setStartDate={setStartDate}
                startDate={startDate}
              />
            )}
            <button
              className='bg-primary mt-8 text-white font-semibold p-1 pl-6 pr-6 rounded-lg'
              onClick={() => addContractRemainder()}
            >
              Save
            </button>
          </>
        ) : (
          <>
            <h2 className='text-primary text-lg font-bold text-center mt-8'>
              {showFeedback.message}
            </h2>
            <button
              className='bg-primary mt-8 text-white font-semibold p-1 pl-6 pr-6 block mx-auto rounded-lg'
              onClick={() => setIsOpen(false)}
            >
              Accept
            </button>
          </>
        )}
      </Modal>
    </div>
  );
};

export default AddReminderModal;
