import React, { useState } from 'react';
import Modal from 'react-modal';
import './modal.css';
import useAxios from '../../hooks/useAxios';
import { resolve } from '../../api/resolve';
import LoadingScreen from '../loader/LoadingScreen';
const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/g;
const customStyles = {
  content: {
    top: '40%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 300,
    maxHeight: '220px',
    borderRadius: '11px',
  },
  overlay: {
    backgroundColor: 'rgba(64, 8, 53, 0.63)',
  },
};

Modal.setAppElement('#root');
const AddClause = ({
  modalIsOpen,
  setIsOpen,
  getClauses,
  contract_id,
  setCurrentClauseKey,
  checkIsDiscardAvailable,
}) => {
  const api = useAxios();
  const [errMsg, setErrMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [clauseName, setClauseName] = useState('');

  const addNewClause = async () => {
    if (clauseName) {
      const clause_key = clauseName
        .toLowerCase()
        .replaceAll('clause', '')
        .trim()
        .replaceAll(format, '')
        .replaceAll(' ', '_');

      if (clause_key.length > 0) {
        setLoading(true);
        const res = await resolve(
          api
            .post(
              `/reviewer/add_new_clause/${contract_id}`,
              JSON.stringify({
                clause_key,
              }),
              {
                headers: {
                  'content-type': 'application/json',
                },
              }
            )
            .then((res) => res.data)
        );
        if (res.data) {
          setCurrentClauseKey(
            clauseName.replaceAll(format, '').replaceAll(' ', '_')
          );
          getClauses({ clauseId: res.data.clause_id, isPrimary: false });
          checkIsDiscardAvailable(res.data.clause_id);
          setIsOpen(false);
        } else {
          setLoading(false);
          if (res.error.response.data.code === 'duplicate_clause_key')
            setErrMsg('Clause already exists. Add a new one');
          else setErrMsg('Clause was not added. Try again');
        }
        setLoading(false);
      }
    }
  };
  if (loading) {
    return <LoadingScreen />;
  }
  return (
    <div className=''>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        contentLabel='Example Modal'
        onAfterClose={() => {
          setErrMsg('');
        }}
      >
        {errMsg ? (
          <>
            <h1 className='text-primary font-semibold text-lg'>{errMsg}</h1>
            <div className='flex justify-center mt-3'>
              <button
                type='button'
                className='bg-primary font-semibold text-white p-1 px-5 rounded-lg w-2/3'
                onClick={() => setIsOpen(false)}
              >
                Accept
              </button>
            </div>
          </>
        ) : (
          <div className='text-center modal-container'>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                addNewClause();
              }}
            >
              <h1 className='text-primary font-bold text-lg'>Add New Clause</h1>
              <input
                placeholder='Clause Name'
                className='border-2 border-primary rounded-lg outline-none p-1 pl-2 w-full my-2'
                onChange={(e) => setClauseName(e.target.value)}
              />
              <div className='flex justify-center'>
                <button
                  type='submit'
                  className='bg-primary font-semibold text-white p-1 px-5 rounded-lg w-2/3'
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default AddClause;
