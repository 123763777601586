import React, { useState } from 'react';
import Modal from 'react-modal';
import useAxios from '../../hooks/useAxios';
import { resolve } from '../../api/resolve';
import './modal.css';
import RepositoryIcon from '../../assets/icons/Repository.svg';
import CheckIcon from '../../assets/icons/check.png';

const customStyles = {
  content: {
    top: '40%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    // maxWidth: '400px',
    maxHeight: '400px',
    borderRadius: '11px',
  },
  overlay: {
    backgroundColor: 'rgba(64, 8, 53, 0.63)',
  },
};

Modal.setAppElement('#root');
const MoveRepoModal = ({
  isModalOpen,
  setIsModalOpen,
  repos,
  selectedIds,
  setPageLoading,
}) => {
  const api = useAxios();
  const [selectedRepo, setSelectedRepo] = useState('');
  const [repoId, setRepoId] = useState(null);
  const moveContracts = async () => {
    if (selectedRepo) {
      setPageLoading(true);
      const res = await resolve(
        api.post(
          '/contract/move_contracts_repo/',
          JSON.stringify({
            id_list: selectedIds,
            repo_name: selectedRepo,
          }),
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
      );
      setIsModalOpen(false);
      setPageLoading(false);
    }
  };
  return (
    <div className=''>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={customStyles}
        contentLabel='Example Modal'
        onAfterClose={() => setRepoId(null)}
      >
        <div className='text-center modal-container'>
          <h2 className='text-primary font-bold text-base'>
            Select a repository
          </h2>
          <div className='grid grid-cols-4  w-full justify-between'>
            {repos.map((repo, idx) => (
              <div
                className='flex flex-col items-center relative'
                onClick={() => {
                  setRepoId(idx);
                  setSelectedRepo(repo.repo_name);
                }}
              >
                <img
                  src={RepositoryIcon}
                  className='cursor-pointer mb-1 mt-3'
                  width={40}
                />
                {repoId === idx && <img src={CheckIcon} className='absolute' />}
                <p className='text-sm font-bold cursor-pointer'>{`${repo.repo_name[0].toUpperCase()}${repo.repo_name.slice(
                  1
                )} `}</p>
              </div>
            ))}
          </div>
          <div className='flex justify-center mt-5'>
            <button
              className='bg-primary hover:bg-secondary font-semibold text-white p-1 pl-8 pr-8 rounded-lg mr-5'
              onClick={(e) => {
                e.stopPropagation();
                moveContracts();
              }}
            >
              Confirm
            </button>
            <button
              className='bg-[#606060] font-semibold text-white p-1 pl-8 pr-8 rounded-lg'
              onClick={(e) => {
                e.stopPropagation();
                setIsModalOpen(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MoveRepoModal;
