import React from 'react';
import Deploy from '../../assets/icons/deploy.png';
import Research from '../../assets/icons/research.png';
import Settings from '../../assets/icons/Settings.svg';

const AdminClauses = ({ activeLink, setActiveLink, setIsOpen }) => {
  return (
    <>
      <li
        onClick={() => setActiveLink('Review')}
        className={
          activeLink === 'Review' || activeLink === 'DetailedReview'
            ? 'active-link'
            : undefined
        }
      >
        <img src={Research} />
        <span>Review</span>
      </li>
      <li
        onClick={() => setActiveLink('Training')}
        className={
          activeLink === 'Training' || activeLink === 'TrainingResults'
            ? 'active-link'
            : undefined
        }
      >
        <img src={Deploy} width={25} />
        <span>Train & Deploy</span>
      </li>
      <li
        onClick={() => setActiveLink('Account')}
        className={activeLink === 'Account' ? 'active-link' : undefined}
      >
        <img src={Settings} />
        <span>Account</span>
      </li>
    </>
  );
};

export default AdminClauses;
