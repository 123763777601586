import { createContext, useEffect, useState } from 'react';
import useAxios from '../hooks/useAxios';
import { resolve } from '../api/resolve';

const AppContext = createContext();

export default AppContext;

export const AppProvider = ({ children }) => {
  const api = useAxios();
  const [activeLink, setActiveLink] = useState('Home');
  const [companiesList, setCompaniesList] = useState([]);
  const [contractsHeading, setContractsHeading] = useState('Search');
  const [isRepoList, setIsRepoList] = useState(false);
  const [repoName, setRepoName] = useState('');
  const [repoID, setRepoID] = useState(null);
  const [sasToken, setSasToken] = useState({
    sas_token: '',
    expiry: '',
  });
  const [highlightToken, setHighlightToken] = useState({
    sas_token: '',
    expiry: '',
  });
  const [notificationCount, setNotificationCount] = useState(0);
  const [totalNotificationCount, setTotalNotificationCount] = useState(0);
  const [routedSearchID, setRoutedSearchID] = useState(null);
  const [contractTypes, setContractTypes] = useState([]);
  const [selectedContractType, setSelectedContractType] = useState(null);
  const [reviewSelection, setReviewSelection] = useState({
    file_url: '',
    doc_name: '',
    converted_file_url: '',
    id: '',
    contract_clause: '',
  });
  const [modelID, setModelID] = useState(null);
  const getCompanies = async () => {
    const res = await resolve(api.get(`/company/all/`).then((res) => res.data));
    if (res.data) {
      const { results } = res.data;
      const filterData = [];
      if (results.length > 0) {
        results.forEach((result) => {
          filterData.push({
            value: result.name,
            label: result.name,
            id: result.id,
            company_name: result.name,
          });
        });
      }
      setCompaniesList([...filterData]);
    }
  };

  const getSasToken = async () => {
    const res = await resolve(
      api.get(`/contract/get_sas_token/`).then((res) => res.data)
    );
    if (res.data) {
      const { sas_token, expiry } = res.data;
      setSasToken({ sas_token, expiry });
    }
  };
  const getHighlightSasToken = async () => {
    const res = await resolve(
      api.get(`/contract/get_sas_token_highlight/`).then((res) => res.data)
    );
    if (res.data) {
      const { sas_token, expiry } = res.data;
      setHighlightToken({ sas_token, expiry });
    }
  };

  const getNotificationsCount = async () => {
    const res = await resolve(
      api.get(`notification/update_is_seen/0`).then((res) => res.data)
    );
    if (res.data) {
      const { notif_count, total_notif_count } = res.data;
      setNotificationCount(notif_count);
      setTotalNotificationCount(total_notif_count);
    }
  };

  useEffect(() => {
    getCompanies();
    getSasToken();
  }, []);
  const contextData = {
    activeLink,
    setActiveLink,
    companiesList,
    getCompanies,
    repoName,
    setRepoName,
    repoID,
    setRepoID,
    contractsHeading,
    setContractsHeading,
    isRepoList,
    setIsRepoList,
    sasToken,
    setSasToken,
    getSasToken,
    notificationCount,
    getNotificationsCount,
    setRoutedSearchID,
    routedSearchID,
    getHighlightSasToken,
    setHighlightToken,
    highlightToken,
    setNotificationCount,
    totalNotificationCount,
    contractTypes,
    setContractTypes,
    selectedContractType,
    setSelectedContractType,
    reviewSelection,
    setReviewSelection,
    modelID,
    setModelID,
  };

  return (
    <AppContext.Provider value={contextData}>{children}</AppContext.Provider>
  );
};
