import React, { useEffect, useState, useContext } from 'react';
import Select from 'react-select';
import dayjs from 'dayjs';
import AppContext from '../../../context/AppContext';
import LoadingScreen from '../../loader/LoadingScreen';
import Search from '../../../assets/icons/Search.svg';
import CloseIcon from '../../../assets/icons/Close.svg';
import Settings from '../../../assets/icons/Settings.svg';
import { dropDownStyles } from '../../../constants/styles';
import AdminTable from '../AdminTable';
import { adminDashbaoradTableColumns } from '../../../constants/constants';
import useAxios from '../../../hooks/useAxios';
import { resolve } from '../../../api/resolve';
import TrainingSteps from './TrainingSteps';
import AppModal from '../../modals/Modal';

const Training = () => {
  const api = useAxios();
  const {
    setContractTypes,
    contractTypes,
    selectedContractType,
    setSelectedContractType,
    reviewSelection,
    setReviewSelection,
    modelID,
    setModelID,
    setActiveLink,
  } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [searchText, setSearchText] = useState('');
  const [nextPage, setIsNextPage] = useState(null);
  const [prevPage, setIsPrevPage] = useState(null);
  const [pageLimit, setPageLimit] = useState(5);
  const [contractCount, setContractCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortingOrder, setSortingOrder] = useState('desc');
  const [sortColumn, setSortColumn] = useState('-created_at');
  const [contracts, setContracts] = useState([]);
  const [contractTypesList, setContractsTypesList] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [isTraining, setIsTraining] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [bodyText, setBodyText] = useState('');
  const [recentModels, setRecentModels] = useState([]);

  const getContracts = async () => {
    setLoading(true);
    const res = await resolve(
      api
        .get(
          `/reviewer/get_reviewed_contracts/?ordering=${sortColumn}&page=${currentPage}`
        )
        .then((res) => res.data)
    );

    if (res.data) {
      const { results, next, previous, count } = res.data;
      const filterData = [];
      if (results.length > 0) {
        setContractCount(count);
        results.forEach((result) => {
          const contractObj = {
            name: result.name || result.doc_name,
            created_at: dayjs(result.created_at).format('DD MMMM YYYY'),
            tags: result.tags,
            type: result.type,
            updated_at: dayjs(result.updated_at).format('D MMMM YYYY'),
            renewal_date: result.renewal_date
              ? dayjs(result.renewal_date).format('DD MMMM YYYY')
              : 'Not Added',
            company_id: result.company_id,
            id: result.id,
            converted_file_url: result.converted_file_url,
            file_url: result.file_url,
            doc_name: result.doc_name,
            reminder_days: result.remainder_days,
            reminder_date: result.remainder_date,
            uploaded_by: result.uploaded_by,
          };
          filterData.push(contractObj);
        });
      } else {
        if (currentPage === 1) setErrMsg('No contracts added yet');
        else setErrMsg('No more contracts to view');
      }
      setContracts([...filterData]);
      setIsNextPage(next);
      setIsPrevPage(previous);
    } else {
      setIsNextPage(null);
    }
    setPageLoading(false);
  };

  const startTraining = async () => {
    const res = await resolve(
      api.get(`/reviewer/train_model/`).then((res) => res.data)
    );
    if (res.data) {
      setIsTraining(true);
      setModelID(res.data.model_id);
    } else {
      setIsOpen(true);
      setTitle('Training Failed');
      setBodyText('Unable to start Training');
    }
  };
  const getRecentModels = async () => {
    setLoading(true);
    const res = await resolve(
      api.get(`/reviewer/get_recent_training_details/`).then((res) => res.data)
    );
    if (res.data) {
      setRecentModels([...res.data]);
    }
    setLoading(false);
  };
  useEffect(() => {
    // setActiveLink('TrainingResults');
    getRecentModels();
    getContracts();
  }, [contractTypes, selectedContractType, sortColumn, currentPage]);

  if (loading) {
    return <LoadingScreen />;
  }
  return (
    <div className='text-primary'>
      <h1 className='text-4xl font-bold'>Reviewed Contracts</h1>
      {/* <form>
        <div className='flex mt-3'>
          <Select
            options={contractTypes}
            placeholder='Contract Type'
            styles={dropDownStyles}
            isSearchable={true}
            className='w-3/12 text-primary'
            onChange={(repo) => {
              setSelectedContractType(repo);
            }}
            defaultValue={contractTypes[0]}
          />
          <div className='flex justify-between items-center border-2 border-primary rounded-md rounded-tl-lg rounded-bl-lg bg-inputBackground px-2 mr-2 w-1/3 ml-2'>
            <input
              placeholder='Enter Contract Name here'
              className='px-1 py-1 focus:outline-none bg-transparent w-11/12'
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                setShowClearIcon(true);
                if (!e.target.value.trim()) {
                  setShowClearIcon(false);
                }
              }}
            />
            {showClearIcon && (
              <img
                src={CloseIcon}
                width={14}
                className='cursor-pointer'
                onClick={() => {
                  setSearchText('');
                  getContracts(true);
                  setShowClearIcon(false);
                }}
              />
            )}
          </div>
          <button
            type='submit'
            className='flex bg-primary text-white  px-4 rounded-lg items-center justify-between'
            onClick={(e) => {
              e.preventDefault();
              getContracts();
            }}
          >
            <img src={Search} style={{ width: '1rem' }} />
            <span className='font-semibold ml-1'>Search</span>
          </button>
        </div>
      </form> */}
      <div className='mt-3 rounded-lg'>
        {contracts.length < 1 && (
          <p className='text-red-500 text-sm font-medium'>{errMsg}</p>
        )}
      </div>
      <AdminTable
        contractTableColumns={adminDashbaoradTableColumns}
        contractsData={contracts}
        nextPage={nextPage}
        prevPage={prevPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        sortingOrder={sortingOrder}
        setSortingOrder={setSortingOrder}
        setSortColumn={setSortColumn}
        pageLimit={pageLimit}
        setPageLimit={setPageLimit}
        contractCount={contractCount}
        setContractCount={setContractCount}
        showPageLimit={false}
      />
      <h2 className='font-semibold text-xl text-center'>Recent Models</h2>
      <div className='flex justify-center items-center my-3'>
        {recentModels.length > 0 &&
          recentModels.map((model) => (
            <div
              onClick={() => {
                setModelID(model.id);
                setActiveLink('TrainingResults');
              }}
              className='border-2 mx-5 border-primary text-center cursor-pointer rounded-md p-2 hover:bg-bgDropdown  font-semibold'
            >
              <h2>Version : {model.version.toFixed(1)}</h2>
              <p>Status: {model.current_progress}</p>
              <p>
                Trained on: {dayjs(model.created_at).format('DD MMMM YYYY')}
              </p>
            </div>
          ))}
      </div>
      <div className={`grid w-full h-80  place-items-center`}>
        {!isTraining ? (
          contracts.length >= 1 && (
            <button
              className='bg-primary text-white p-1 px-3 ml-2 rounded-lg'
              onClick={() => {
                startTraining();
              }}
            >
              <span className='flex'>
                <img src={Settings} width={20} className='rounded-md' />
                <span className='font-semibold ml-2'>
                  Train Reviewed Contracts
                </span>
              </span>
            </button>
          )
        ) : (
          <TrainingSteps
            setTitle={setTitle}
            setBodyText={setBodyText}
            setIsOpen={setIsOpen}
          />
        )}
      </div>
      <AppModal
        title={title}
        bodyText={bodyText}
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
      />
    </div>
  );
};

export default Training;
