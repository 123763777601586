import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import appLogo from '../../assets/app-logo.svg';

const ForgotPasswordForm = ({ formErrors, onSubmit }) => {
  const { loading } = useContext(AuthContext);
  const [email, setEmail] = useState('');

  const onFormSubmit = (e) => {
    e.preventDefault();
    onSubmit(email);
  };

  return (
    <div className=' w-full flex flex-col items-center'>
      <Link to='/'>
        <img
          src={appLogo}
          alt='app-logo'
          style={{ maxWidth: '200px', maxHeight: '200px' }}
        />
      </Link>
      <div className='mt-10 pt-2 pl-5 pr-5 pb-32 bg-white  form-container w-3/4'>
        <p className='font-sans text-center  text-primary font-medium m-3 mb-5'>
          Enter your email to reset your <br /> password
        </p>
        <form className='w-full' onSubmit={(e) => onFormSubmit(e)}>
          <div className='mb-3'>
            <label className='text-primary font-normal'>Email</label>
            <input
              type='text'
              placeholder='Enter email address'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className='w-full p-1 pl-3 focus:outline-none text-primary'
            />
            <p className='text-sm text-red-500'>{formErrors.email}</p>
          </div>
          <button
            type='submit'
            className={`bg-primary ${
              loading && 'bg-gray-500'
            } text-white p-1 font-medium w-full mb-3`}
            disabled={loading}
          >
            Send Verification Email
          </button>
        </form>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
