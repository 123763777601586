import React from 'react';
import { Link } from 'react-router-dom';
import Company from '../../assets/icons/Company.svg';
import Admin from '../../assets/icons/Admin.svg';
import User from '../../assets/icons/User.svg';

const SuperAdmin = ({ activeLink, setActiveLink, currentForm }) => {
  return (
    <>
      <li
        onClick={() => setActiveLink('Companies')}
        className={
          activeLink === 'Companies' || currentForm === 'CompanyForm'
            ? 'active-link'
            : undefined
        }
      >
        <img src={Company} />
        <span>Companies</span>
      </li>
      <li
        onClick={() => setActiveLink('Admins')}
        className={
          activeLink === 'Admins' || currentForm === 'AdminForm'
            ? 'active-link'
            : undefined
        }
      >
        <img src={Admin} />
        <span>Admins</span>
      </li>
      <li
        onClick={() => setActiveLink('Users')}
        className={
          activeLink === 'Users' || currentForm === 'UserForm'
            ? 'active-link'
            : undefined
        }
      >
        <img src={User} />
        <span>Users</span>
      </li>
      <li
        onClick={() => setActiveLink('Reviewers')}
        className={
          activeLink === 'Reviewers' || currentForm === 'ReviewerForm'
            ? 'active-link'
            : undefined
        }
      >
        <img src={User} />
        <span>Reviewers</span>
      </li>
    </>
  );
};

export default SuperAdmin;
