import React from 'react';
import Loader from '../../assets/loader.gif';

const LoadingScreen = () => {
  return (
    <div className='h-full grid place-items-center'>
      <img src={Loader} alt='loading-screen' style={{ maxWidth: '300px' }} />
    </div>
  );
};

export default LoadingScreen;
