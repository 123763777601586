export const appName = 'QUOQO';

export const delay = 300;

export const autoCloseTimer = 3000;

export const companyTableColumns = [
  {
    Header: 'Company',
    accessor: 'name', // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: 'Date Created',
    accessor: 'dateCreated',
    isSorted: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    isSorted: true,
  },
];
export const adminTableColumns = [
  {
    Header: 'User Name',
    accessor: 'first_name', // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: 'Email',
    accessor: 'email', // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: 'Company',
    accessor: 'company_name', // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: 'Date Created',
    accessor: 'dateCreated',
    isSorted: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    isSorted: true,
  },
];
export const userTableColumns = [
  {
    Header: 'User Name',
    accessor: 'first_name', // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: 'Email',
    accessor: 'email', // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: 'Company',
    accessor: 'company_name', // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: 'Date Created',
    accessor: 'dateCreated',
    isSorted: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    isSorted: true,
  },
];
export const contractTableColumns = [
  {
    Header: 'Contract Name',
    accessor: 'name', // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: 'Date Created',
    accessor: 'created_at', // accessor is the "key" in the data
    isSorted: true,
  },
  // {
  //   Header: 'Tag',
  //   accessor: 'tags',
  //   isSorted: true,
  // },
  {
    Header: 'Type of Contract',
    accessor: 'type',
    isSorted: true,
  },
  {
    Header: 'Renewal Date',
    accessor: 'renewal_date',
    isSorted: true,
  },
];
export const notificationTableColumns = [
  {
    Header: 'Title',
    accessor: 'title', // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: 'Date',
    accessor: 'date', // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: 'Read',
    accessor: 'read', // accessor is the "key" in the data
    isSorted: true,
  },
];
export const notificationListColumns = [
  {
    Header: 'Contract Name',
    accessor: 'name', // accessor is the "key" in the data
    isSorted: true,
  },
  // {
  //   Header: 'Repository Name',
  //   accessor: 'repo_name', // accessor is the "key" in the data
  //   isSorted: true,
  // },
];
export const adminDashbaoradTableColumns = [
  {
    Header: 'Contract Name',
    accessor: 'name', // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: 'Date Created',
    accessor: 'created_at', // accessor is the "key" in the data
    isSorted: true,
  },
  {
    Header: 'Type of Contract',
    accessor: 'type',
    isSorted: true,
  },
];
