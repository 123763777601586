import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import CommonModule from './CommonComponent';
import useAxios from '../../hooks/useAxios';
import { resolve } from '../../api/resolve';
import { companyTableColumns } from '../../constants/constants';

const Companies = () => {
  const api = useAxios();
  const [companies, setCompanies] = useState([]);
  const [nextPage, setIsNextPage] = useState(null);
  const [prevPage, setIsPrevPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [errMsg, setErrMsg] = useState('');
  const [pageLoading, setPageLoading] = useState(true);
  const [sortingOrder, setSortingOrder] = useState('desc');
  const [sortColumn, setSortColumn] = useState('-created_at');
  const getCompanies = async () => {
    const res = await resolve(
      api
        .get(`/company/view_create/?ordering=${sortColumn}&page=${currentPage}`)
        .then((res) => res.data)
    );
    if (res.data) {
      const { results, next, previous } = res.data;
      const filterData = [];
      if (results.length > 0) {
        results.forEach((result) => {
          const userObj = {
            name: result.name,
            dateCreated: dayjs(result.created_at).format('D MMMM YYYY'),
            status: result.is_active ? 'Active' : 'In-Active',
            phone: result.phone,
            address: result.address,
            id: result.id,
            is_active: result.is_active,
            renew_date: result.renew_date,
          };
          filterData.push(userObj);
        });
      } else {
        setErrMsg('No companies added yet');
      }
      setCompanies([...filterData]);
      setIsNextPage(next);
      setIsPrevPage(previous);
    }
    setPageLoading(false);
  };

  const searchCompany = async (searchText) => {
    if (searchText.trim().length > 0) {
      const res = await resolve(
        api
          .get(`/company/view_create/?search=${searchText}`)
          .then((res) => res.data)
      );
      if (res.data) {
        const { results, next, previous } = res.data;
        const filterData = [];
        if (results.length > 0) {
          results.forEach((result) => {
            const userObj = {
              name: result.name,
              dateCreated: dayjs(result.created_at).format('D MMMM YYYY'),
              status: result.is_active ? 'Active' : 'In-Active',
              phone: result.phone,
              address: result.address,
              id: result.id,
              is_active: result.is_active,
              renew_date: result.renew_date,
            };
            filterData.push(userObj);
          });
        } else {
          setErrMsg('No company found');
        }
        setCompanies([...filterData]);
        setIsNextPage(next);
        setIsPrevPage(previous);
      }
    } else {
      getCompanies();
    }
  };
  useEffect(() => {
    getCompanies();
  }, [currentPage, sortColumn]);
  return (
    <CommonModule
      heading='Companies'
      inputPlaceholder='Enter company name here'
      addBtnText='Add New Company'
      errMsg={errMsg}
      moduleData={companies}
      moduleTableColumns={companyTableColumns}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      isCompany={true}
      nextPage={nextPage}
      prevPage={prevPage}
      getSearchData={searchCompany}
      activeLinkTitle='CompanyForm'
      pageLoading={pageLoading}
      sortingOrder={sortingOrder}
      setSortingOrder={setSortingOrder}
      setSortColumn={setSortColumn}
      getModuleData={getCompanies}
    />
  );
};

export default Companies;
