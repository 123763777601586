import React, { useState, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { validateEmail } from '../../utils/utils';
import AuthContext from '../../context/AuthContext';
import ForgotPasswordUI from './ForgotPasswordUI';

const ForgotPassword = ({ title }) => {
  const { sendPasswordResetLink, user } = useContext(AuthContext);
  const [formErrors, setFormErrors] = useState({ email: '' });
  if (user) {
    return <Navigate to='/dashboard' />;
  }
  const onSubmit = (email) => {
    if (!validateEmail(email)) {
      setFormErrors({ ...formErrors, email: 'Please enter a valid email!' });
    } else {
      sendPasswordResetLink(email);
    }
  };
  return (
    <ForgotPasswordUI
      title={title}
      formErrors={formErrors}
      onSubmit={onSubmit}
    />
  );
};

export default ForgotPassword;
