import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import './navbar.css';
import navbarLogo from '../../assets/navbar-logo.svg';
import Home from '../../assets/icons/Home.svg';
import Settings from '../../assets/icons/Settings.svg';
import Help from '../../assets/icons/Help.svg';
import Logout from '../../assets/icons/Logout.svg';
import NDA from '../../assets/icons/NDA.svg';
import CLM from '../../assets/icons/CLM.svg';
import SuperAdmin from './SuperAdmin';
import AdminUser from './AdminUser';
import AdminClauses from './AdminClauses';
import AuthContext from '../../context/AuthContext';
import FormContext from '../../context/FormContext';
import AppModal from '../modals/Modal';

const Navbar = ({ activeLink, setActiveLink }) => {
  const { user } = useContext(AuthContext);
  const { currentForm } = useContext(FormContext);
  const [modalIsOpen, setIsOpen] = useState(false);

  // if (user.user_type === 'reviewer') {
  //   return (
  //     <AdminClauses
  //       activeLink={activeLink}
  //       setActiveLink={setActiveLink}
  //       setIsOpen={setIsOpen}
  //     />
  //   );
  // }
  return (
    <div className='bg-primary h-full'>
      <div>
        <Link to='/dashboard'>
          <img src={navbarLogo} />
        </Link>
      </div>
      <div className='mt-16'>
        <ul className='text-white font-semibold navlinks-container'>
          <li
            onClick={() => setActiveLink('Home')}
            className={activeLink === 'Home' ? 'active-link' : undefined}
          >
            <img src={Home} />
            <span>Home</span>
          </li>
          {user.user_type === 'reviewer' ? (
            <AdminClauses
              activeLink={activeLink}
              setActiveLink={setActiveLink}
              setIsOpen={setIsOpen}
            />
          ) : (
            <>
              {user.user_type === 'super_admin' && (
                <SuperAdmin
                  activeLink={activeLink}
                  setActiveLink={setActiveLink}
                  currentForm={currentForm}
                />
              )}
              {user.user_type !== 'super_admin' && (
                <AdminUser
                  activeLink={activeLink}
                  setActiveLink={setActiveLink}
                  user={user}
                  currentForm={currentForm}
                />
              )}
              {user.user_type !== 'super_admin' && (
                <>
                  <li>
                    <a
                      href='https://nda.quoqo.app/login-2/'
                      target='_blank'
                      className='flex'
                    >
                      <img src={NDA} />
                      <span>Quoqo NDA</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://quoqo.app/users/login'
                      target='_blank'
                      className='flex'
                    >
                      <img src={CLM} />
                      <span>Quoqo CLM</span>
                    </a>
                  </li>
                </>
              )}
            </>
          )}
          {user.user_type !== 'reviewer' && (
            <li
              onClick={() => setActiveLink('Account')}
              className={activeLink === 'Account' ? 'active-link' : undefined}
            >
              <img src={Settings} />
              <span>Account</span>
            </li>
          )}
          {user.user_type !== 'reviewer' && (
            <li
              onClick={() => setActiveLink('Help')}
              className={activeLink === 'Help' ? 'active-link' : undefined}
            >
              <img src={Help} />
              <span>Help</span>
            </li>
          )}
          <li onClick={() => setIsOpen(true)}>
            <img src={Logout} />
            <span>Logout</span>
          </li>
        </ul>
      </div>
      <AppModal
        setIsOpen={setIsOpen}
        modalIsOpen={modalIsOpen}
        isLogin={true}
        title='Do you want to Log out?'
      />
    </div>
  );
};

export default Navbar;
