import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import appLogo from '../../assets/app-logo.svg';
import { validatePassword } from '../../utils/utils';

const ResetPasswordForm = ({ onSubmit }) => {
  const { loading } = useContext(AuthContext);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [formErrors, setFormErrors] = useState({
    password: '',
    confirmPassword: '',
  });

  const onFormSubmit = (e) => {
    e.preventDefault();
    if (!validatePassword(password)) {
      setFormErrors({
        ...formErrors,
        password: ` Password should contain : 
        1 Alphabet, 1 Number, 1 Special character, Minimum 8 characters `,
      });
      return;
    } else if (confirmPassword !== password) {
      setFormErrors({
        ...formErrors,
        confirmPassword: 'Passwords do not match',
      });
      return;
    } else {
      onSubmit(password);
    }
  };

  return (
    <div className=' w-full flex flex-col items-center'>
      <Link to='/'>
        <img
          src={appLogo}
          alt='app-logo'
          style={{ maxWidth: '200px', maxHeight: '200px' }}
        />
      </Link>
      <div className='mt-10 pt-2 pl-5 pr-5 pb-20 bg-white  form-container w-3/4'>
        {/* <p className='font-sans text-center  text-primary font-medium m-3 mb-5'>
          Enter your email to reset your <br /> password
        </p> */}
        <form className='w-full mt-8' onSubmit={(e) => onFormSubmit(e)}>
          <div className='mb-3'>
            <label className='text-primary font-normal'>New Password</label>
            <input
              type='password'
              placeholder='******'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className='w-full p-1 pl-3 focus:outline-none text-primary'
            />
            <p className='text-sm text-red-500'>{formErrors.password}</p>
          </div>
          <div className='mb-3'>
            <label className='text-primary font-normal'>Confirm Password</label>
            <input
              type='password'
              placeholder='******'
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className='w-full p-1 pl-3 focus:outline-none text-primary'
            />
            <p className='text-sm text-red-500'>{formErrors.confirmPassword}</p>
          </div>
          <button
            type='submit'
            className={`bg-primary ${
              loading && 'bg-gray-500'
            } text-white p-1 font-medium w-full mb-3`}
            disabled={loading}
          >
            Reset Password
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
