import React, { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import AuthContext from '../../context/AuthContext';
import FormContext from '../../context/FormContext';
import AppModal from '../modals/Modal';

const Account = () => {
  const { user } = useContext(AuthContext);
  const {
    setIsOpen,
    modalIsOpen,
    modalData,
    passwordFormErrors,
    setPasswordFormErrors,
    updatePassword,
  } = useContext(FormContext);
  const [passwordDetails, setPasswordDetails] = useState({
    current: '',
    password: '',
    confirm: '',
    email: user.user_email,
  });

  useEffect(() => {
    setPasswordFormErrors({ current: '', password: '', confirm: '' });
  }, []);
  return (
    <div className='text-primary text-base font-normal font-sans'>
      <h1 className='text-4xl font-bold'>Account</h1>

      <div className='mt-16'>
        <div className='mb-3'>
          <span className='text-base font-bold mr-2'>Name :</span>
          <span className='text-lg font-medium text-[#79838B]'>{`${user.name[0].toUpperCase()}${user.name.slice(
            1
          )}`}</span>
        </div>
        <div className='mb-3'>
          <span className='text-base font-bold mr-2'>Email :</span>
          <span className='text-lg font-medium text-[#79838B]'>
            {user.user_email}
          </span>
        </div>
        <div className='mb-3'>
          <span className='text-base font-bold mr-2'>Date Created :</span>
          <span className='text-lg font-medium text-[#79838B]'>
            {dayjs(user.user_created_at).format('MMMM DD, YYYY')}
          </span>
        </div>
        {user.user_type === 'admin' || user.user_type === 'user' ? (
          <div className='mb-3'>
            <span className='text-base font-bold mr-2'>Company :</span>
            <span className='text-lg font-medium text-[#79838B]'>
              {`${user.user_company[0].toUpperCase()}${user.user_company.slice(
                1
              )}`}
            </span>
          </div>
        ) : null}
        <div className='mb-3'>
          <span className='text-base font-bold mr-2'>User Type :</span>
          <span className='text-lg font-medium text-[#79838B]'>{`${user.user_type[0].toUpperCase()}${user.user_type.slice(
            1
          )}`}</span>
        </div>
        <div className='mb-3'>
          <span className='text-base font-bold mr-2'>Status :</span>
          <span className='text-lg font-medium text-[#79838B]'>{`${user.user_status[0].toUpperCase()}${user.user_status.slice(
            1
          )}`}</span>
        </div>
        <div className='mb-3'>
          <span className='text-base font-bold mr-2'>Version :</span>
          <span className='text-lg font-medium text-[#79838B]'>
            {process.env.REACT_APP_VERSION}
          </span>
        </div>

        <div className='mt-5 w-2/5'>
          <h1 className='text-base font-bold mb-2'>
            Update your account password:
          </h1>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              updatePassword(passwordDetails);
            }}
          >
            <div className='flex flex-col mb-3'>
              <label>Enter current password</label>
              <input
                placeholder='*****'
                className='border-2 border-primary rounded-lg outline-none p-0.5 pl-2'
                value={passwordDetails.current}
                onChange={(e) =>
                  setPasswordDetails({
                    ...passwordDetails,
                    current: e.target.value,
                  })
                }
                type='password'
              />
              <p className='text-sm text-red-500 font-normal'>
                {passwordFormErrors.current}
              </p>
            </div>
            <div className='flex flex-col mb-3'>
              <label>Enter new password</label>
              <input
                placeholder=''
                className='border-2 border-primary rounded-lg outline-none p-0.5 pl-2'
                value={passwordDetails.password}
                onChange={(e) =>
                  setPasswordDetails({
                    ...passwordDetails,
                    password: e.target.value,
                  })
                }
                type='password'
              />
              <p className='text-sm text-red-500 font-normal'>
                {passwordFormErrors.password}
              </p>
            </div>
            <div className='flex flex-col mb-3'>
              <label>Confirm new password</label>
              <input
                placeholder=''
                className='border-2 border-primary rounded-lg outline-none p-0.5 pl-2'
                value={passwordDetails.confirm}
                onChange={(e) =>
                  setPasswordDetails({
                    ...passwordDetails,
                    confirm: e.target.value,
                  })
                }
                type='password'
              />
              <p className='text-sm text-red-500 font-normal'>
                {passwordFormErrors.confirm}
              </p>
            </div>
            <button
              className='bg-primary font-semibold text-white w-full p-1 rounded-lg mt-3'
              type='submit'
            >
              Update Password
            </button>
          </form>
        </div>
      </div>
      <AppModal
        title={modalData.title}
        bodyText={modalData.bodyText}
        setIsOpen={setIsOpen}
        modalIsOpen={modalIsOpen}
      />
    </div>
  );
};

export default Account;
