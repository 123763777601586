import React, { useState, useEffect } from 'react';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import { resolve } from '../../api/resolve';
import useAxios from '../../hooks/useAxios';
import Company from '../../assets/icons/Company-sm.svg';
import Folder from '../../assets/icons/Folder-sm.svg';
import Admin from '../../assets/icons/Admin-sm.svg';
import User from '../../assets/icons/User-sm.svg';
import Contract from '../../assets/icons/Contract-sm.svg';
import NDA from '../../assets/icons/NDA-sm.svg';
import CLM from '../../assets/icons/CLM-sm.svg';

const CircularProgress = ({ user }) => {
  const api = useAxios();
  const [percentage, setPercentage] = useState(12.5);
  const [chartData, setChartData] = useState(null);

  const getProgressChartData = async () => {
    const res = await resolve(
      api.get(`/dashboard/progress_bar_stats/`).then((res) => res.data)
    );
    if (res.data) {
      setChartData(res.data);
    }
  };

  useEffect(() => {
    getProgressChartData();
  }, []);
  return (
    <div className='border-2 border-primary text-primary circular-container p-5 mt-5 w-full'>
      <h1 className='font-bold text-xl'>Usage in repository</h1>
      <p className='mb-2'>Statistics sub-information</p>
      <div className='flex items-center'>
        <div
          style={{
            width: 150,
            height: 150,
          }}
          className='mr-5'
        >
          {chartData && (
            <CircularProgressbarWithChildren
              value={Math.floor(100 - chartData.space_used_per)}
              styles={buildStyles({
                strokeLinecap: 'butt',
                pathColor: `#400835`,
                textColor: '#f88',
                trailColor: '#d6d6d6',
                backgroundColor: '#3e98c7',
              })}
            >
              <div className='text-center text-primary'>
                <span className='font-bold text-4xl ml-2'>
                  {Math.floor(100 - chartData.space_used_per)}%
                </span>
                <p className='text-xs font-bold mt-1 space-x-10'>AVAILABLE</p>
              </div>
            </CircularProgressbarWithChildren>
          )}
        </div>
        {chartData && (
          <div className='w-3/5 flex  text-primary font-medium text-sm justify-around'>
            <div>
              {user.user_type === 'super_admin' && (
                <div className='flex items-center mb-3'>
                  <img src={Company} />
                  <span className='ml-1'>
                    {chartData.company_count} Companies
                  </span>
                </div>
              )}

              <div className='flex items-center mb-3'>
                <img src={Admin} />
                <span className='ml-1'>{chartData.admin_count} Admins</span>
              </div>
              <div className='flex items-center mb-3'>
                <img src={Contract} />
                <span className='ml-1'>
                  {chartData.contracts_count} Contracts
                </span>
              </div>
              <div className='flex items-center'>
                <img src={CLM} />
                <span className='ml-1'>
                  {chartData.clm_count === 'NA' ? 0 : chartData.clm_count} CLM
                </span>
              </div>
            </div>
            <div>
              <div className='flex items-center mb-3'>
                <img src={Folder} />
                <span className='ml-1'>
                  {chartData.repo_count} Repositories
                </span>
              </div>
              <div className='flex items-center mb-3'>
                <img src={User} />
                <span className='ml-1'>{chartData.user_count} Users</span>
              </div>
              <div className='flex items-center mb-3'>
                <img src={NDA} />
                <span className='ml-1'>
                  {chartData.nda_count === 'NA' ? 0 : chartData.nda_count} NDAs
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CircularProgress;
