import React, { useState } from 'react';
import './helpModule.css';
import { Helmet } from 'react-helmet';
import CallIcon from '../../assets/icons/Call.svg';
import RequestIcon from '../../assets/icons/Request.svg';
import HelpModuleModal from '../modals/HelpModuleModal';

const HelpModule = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isSupportRequest, setIsSupportRequest] = useState(false);

  return (
    <div className='text-primary text-base font-normal font-sans'>
      <h1 className='text-4xl font-bold'>Help</h1>
      <div className='flex  w-1/2 justify-between mt-8'>
        <div
          className='flex items-center cursor-pointer'
          onClick={() => {
            setIsOpen(true);
            setIsSupportRequest(true);
          }}
        >
          <div className='border-primary help-module-item'>
            <img src={RequestIcon} width={40} />
          </div>
          <div className='ml-3'>
            <h2 className='text-base font-bold'>
              Send us a <br /> support request
            </h2>
          </div>
        </div>
        <div
          className='flex items-center cursor-pointer'
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <div className='border-primary help-module-item'>
            <img src={CallIcon} width={50} />
          </div>
          <div className='ml-3'>
            <h2 className='text-base font-bold'>Schedule a call</h2>
            <p className='text-sm'>
              ( Available Mon-Fri during <br /> normal business hours )
            </p>
          </div>
        </div>
      </div>
      <HelpModuleModal
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        isSupportRequest={isSupportRequest}
        setIsSupportRequest={setIsSupportRequest}
      />
      {/* <Helmet>
        <script
          type='text/javascript'
          src='//js-na1.hs-scripts.com/9389699.js'
          async
          defer
          id='hs-script-loader'
        ></script>
      </Helmet> */}
    </div>
  );
};

export default HelpModule;
