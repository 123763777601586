import React, { useState, useEffect, useContext, useRef } from 'react';
import './detailedComponent.css';
import Creatable from 'react-select/creatable';
import dayjs from 'dayjs';
import { addListener, launch, stop } from 'devtools-detector';
import ReactTooltip from 'react-tooltip';
import TextSelector from 'text-selection-react';
import CryptoJS from 'crypto-js';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { searchPlugin } from '@react-pdf-viewer/search';
import { resolve } from '../../api/resolve';
import useAxios from '../../hooks/useAxios';
import AppContext from '../../context/AppContext';
import AuthContext from '../../context/AuthContext';
import LoadingScreen from '../loader/LoadingScreen';
import { customStyles } from '../../constants/styles';
import NextRounded from '../../assets/icons/NextRounded.svg';
import PrevRounded from '../../assets/icons/PrevRounded.svg';
import Group from '../../assets/icons/group.png';
import DownArrow from '../../assets/icons/down-arrow.svg';
import SearchIcon from '../../assets/icons/Search-sm.svg';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import ClauseItem from './ClauseItem';
import { formatSelectedClause } from '../../utils/utils';
import AddClause from '../modals/AddClause';
import AppModal from '../modals/Modal';
import DeleteModal from '../modals/DeleteModal';
const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/g;

const DetailedComponent = () => {
  const api = useAxios();
  const ref = useRef(null);
  const [loading, setLoading] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [contractClauses, setContractClauses] = useState([]);
  const [clauseValue, setClauseValue] = useState('');
  const [docUrl, setDocUrl] = useState('');
  const [selectedId, setSelectedId] = useState(null);
  const [inputDisabled, setInputDisabled] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedClause, setSelectedClause] = useState({ name: '', id: null });
  const [valueSelected, setValueSelected] = useState('');
  const [currentClauseValue, setCurrentClauseValue] = useState('');
  const [modalIsOpen, setIsOpen] = useState(false);
  const [saveModalOpen, setIsSaveOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [bodyText, setBodyText] = useState('');
  const [pagination, setPagination] = useState({ next: null, prev: null });
  const [saveOption, setSaveOption] = useState('');
  const [showDiscardBtn, setShowDiscardBtn] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [types, setTypes] = useState('');
  const [defaultTypes, setDefaultTypes] = useState([]);
  const [typesLoaded, setTypesLoaded] = useState(false);
  const [currentClauseKey, setCurrentClauseKey] = useState(null);
  const [disabledClauses, setDisabledClauses] = useState({
    party: '',
    execution: '',
  });
  const [textSearched, setTextSearched] = useState(false);
  const [dockOpened, setDockopened] = useState(false);
  const {
    contractTypes,
    selectedContractType,
    reviewSelection,
    setReviewSelection,
    setSelectedContractType,
    sasToken,
    highlightToken,
    getHighlightSasToken,
  } = useContext(AppContext);
  addListener((isOpen) => {
    if (isOpen) setDockopened(true);
    else setDockopened(false);
  });
  launch();
  const { user } = useContext(AuthContext);
  const searchPluginInstance = searchPlugin({
    onHighlightKeyword: (props) => {
      props.highlightEle.style.backgroundColor = 'red';
    },
  });
  const { Search } = searchPluginInstance;
  const renderToolbar = (Toolbar) => (
    <Toolbar>
      {(slots) => {
        const {
          CurrentPageInput,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          ShowSearchPopover,
          Print,
          CurrentScale,
          ZoomIn,
          ZoomOut,
          zoomTo,
        } = slots;
        return (
          <div className={`w-full`}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ padding: '0px 2px', marginTop: 5 }}>
                <ShowSearchPopover>
                  {(props) => (
                    <Search>
                      {(renderSearchProps) => {
                        return (
                          <div className='flex'>
                            <div
                              className='border-2 border-primary bg-[#EBE9E9] w-60 p-1 rounded-lg flex'
                              onLoadCapture={() => {
                                // renderSearchProps.setKeyword(keyword); enable it once watermark is ready
                                if (clauseValue) {
                                  let keyword = '';
                                  const keywordArr = clauseValue
                                    .replaceAll('#@#', '')
                                    .split('\n');
                                  if (keywordArr.length > 1) {
                                    keyword = keywordArr[1].trim();
                                  } else {
                                    keyword = keywordArr[0].trim();
                                  }
                                  renderSearchProps.setKeyword(keyword);
                                }
                              }}
                              onLoad={() => {
                                // enable it once watermark is ready
                                // if (keyword) {
                                //   renderSearchProps
                                //     .search()
                                //     .then(() =>
                                //       renderSearchProps.setKeyword('')
                                //     );
                                // }
                                if (clauseValue && !textSearched) {
                                  renderSearchProps.search().then(() => {
                                    setTimeout(
                                      () => setTextSearched(true),
                                      5000
                                    );
                                    renderSearchProps.setKeyword('');
                                  });
                                }
                              }}
                            >
                              <img
                                src={SearchIcon}
                                style={{ width: 20, height: 20, margin: 2 }}
                              />
                              <input
                                className='bg-transparent focus:border-none outline-none w-full text-sm font-bold text-primary'
                                placeholder='Search within the document'
                                type='text'
                                onChange={(e) => {
                                  renderSearchProps.clearKeyword();
                                  renderSearchProps.setKeyword(e.target.value);
                                }}
                                onKeyDown={(e) => {
                                  if (
                                    e.keyCode === 13 &&
                                    renderSearchProps.keyword
                                  ) {
                                    e.preventDefault();
                                    renderSearchProps.search();
                                  }
                                }}
                              />
                            </div>
                            {renderSearchProps.keyword &&
                              renderSearchProps.numberOfMatches > 0 && (
                                <div className='flex ml-1 items-center'>
                                  <p>
                                    {renderSearchProps.currentMatch} of{' '}
                                    {renderSearchProps.numberOfMatches}
                                  </p>
                                  <div className='flex flex-col items-center ml-1'>
                                    <span
                                      disabled={props.isDisabled}
                                      onClick={
                                        renderSearchProps.jumpToPreviousMatch
                                      }
                                      style={{
                                        cursor: 'pointer',
                                        transform: 'rotate(180deg)',
                                        width: 15,
                                        height: 15,
                                      }}
                                      className='ml-1'
                                      data-tip='Previous match'
                                    >
                                      <img src={DownArrow} />
                                    </span>
                                    <ReactTooltip
                                      backgroundColor='#400835'
                                      textColor='#ffffff'
                                    />
                                    <span
                                      onClick={
                                        renderSearchProps.jumpToNextMatch
                                      }
                                      style={{
                                        cursor: 'pointer',
                                        width: 15,
                                        height: 15,
                                      }}
                                      className='ml-1'
                                      data-tip='Next match'
                                    >
                                      <img src={DownArrow} />
                                    </span>
                                    <ReactTooltip
                                      backgroundColor='#400835'
                                      textColor='#ffffff'
                                    />
                                  </div>
                                </div>
                              )}
                          </div>
                        );
                      }}
                    </Search>
                  )}
                </ShowSearchPopover>
              </div>
              <div className={`flex  items-center mt-2 justify-center`}>
                <div className='w-7'>
                  <CurrentPageInput />
                </div>
                <div className='mx-2'>
                  /{' '}
                  <span>
                    <NumberOfPages />
                  </span>
                </div>
                <div className='flex flex-col ml-1'>
                  <GoToPreviousPage>
                    {(props) => (
                      <>
                        <span
                          disabled={props.isDisabled}
                          onClick={props.onClick}
                          style={{
                            cursor: 'pointer',
                            transform: 'rotate(180deg)',
                            width: 15,
                            height: 15,
                          }}
                          className='ml-1'
                          data-tip='Previous Page'
                        >
                          <img src={DownArrow} />
                        </span>
                        {/* <ReactTooltip
                          backgroundColor='#400835'
                          textColor='#ffffff'
                        /> */}
                      </>
                    )}
                  </GoToPreviousPage>
                  <GoToNextPage>
                    {(props) => (
                      <>
                        <span
                          disabled={props.isDisabled}
                          onClick={props.onClick}
                          style={{
                            cursor: 'pointer',
                            marginTop: 2,
                            width: 15,
                            height: 15,
                          }}
                          className='ml-1'
                          data-tip='Next Page'
                        >
                          <img src={DownArrow} />
                        </span>
                        {/* <ReactTooltip
                          backgroundColor='#400835'
                          textColor='#ffffff'
                        /> */}
                      </>
                    )}
                  </GoToNextPage>
                </div>
              </div>
              <div className='max-w-fit flex items-center'>
                <ZoomIn />
                <span className='mx-1'>{CurrentScale()}</span>
                <ZoomOut />
              </div>
            </div>
          </div>
        );
      }}
    </Toolbar>
  );
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: () => [],
    renderToolbar,
  });
  const getClauseValues = async () => {
    setLoading(true);
    const res = await api.get(
      `/contract/get_clause_value/${reviewSelection.id}/`
    );
    if (res.data) {
      const { next, prev } = res.data;
      setPagination({ ...pagination, next, prev });
    }
    setLoading(false);
  };
  const getLatestClauses = async ({ clauseId, isPrimary }) => {
    setSelectedId(null);
    setLoading(true);
    const res = await api.get(
      `/reviewer/get_reviewer_clause_list/${reviewSelection.id}`
    );
    if (res.data) {
      const filteredArr = [];
      const { contract_key_list, execution_clause, party_clause } = res.data;
      let key = process.env.REACT_APP_ENCRYPT_KEY;
      key = CryptoJS.enc.Utf8.parse(key);
      let party_encrypted = party_clause;
      let party_decrypted =
        party_clause &&
        CryptoJS.AES.decrypt(party_encrypted, key, {
          mode: CryptoJS.mode.ECB,
        });
      let execution_encrypted = execution_clause;
      let execution_decrypted =
        execution_clause &&
        CryptoJS.AES.decrypt(execution_encrypted, key, {
          mode: CryptoJS.mode.ECB,
        });
      setDisabledClauses({
        ...disabledClauses,
        party: party_clause ? party_decrypted.toString(CryptoJS.enc.Utf8) : '',
        execution: execution_clause
          ? execution_decrypted.toString(CryptoJS.enc.Utf8)
          : '',
      });
      contract_key_list.forEach((item) => {
        if (item.key) {
          const formattedText = item.key.split('_').join(' ');
          filteredArr.push({ name: formattedText, id: item.id });
        }
      });
      const clauseSelected = filteredArr.filter((obj) => obj.id === clauseId);
      clauseId
        ? setSelectedClause({
            ...selectedClause,
            name: clauseSelected.length > 0 && clauseSelected[0].name,
            id: clauseSelected.length > 0 && clauseSelected[0].id,
          })
        : setSelectedClause({
            ...selectedClause,
            name: filteredArr.length > 0 && filteredArr[0].name,
            id: filteredArr.length > 0 && filteredArr[0].id,
          });
      if (isPrimary) {
        setSelectedId(0);
      }
      setContractClauses([...filteredArr]);
    }
    setLoading(false);
  };
  const getHighlightedDoc = async ({ id, clause }) => {
    setValueSelected('');
    setPdfLoading(true);
    const res = await resolve(
      api.post(
        '/contract/highlight_pdf_reviewer/',
        JSON.stringify({
          contract_id: reviewSelection.id,
          key_list: clause,
          search_term: '',
          for_review: true,
        }),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
    );
    if (res.data?.data) {
      setClauseValue(res.data.data.value);
      setCurrentClauseValue(res.data.data.new_value.replaceAll('#@#', ''));
      setValueSelected(res.data.data.new_value);
      setDocUrl(res.data.data.file_url);
    }
    setPdfLoading(false);
  };

  const saveReviewerClause = async (isReviewer) => {
    setLoading(true);
    let formattedArr = [];
    if (defaultTypes.length > 0) {
      defaultTypes.forEach((obj) => formattedArr.push(obj.value.toLowerCase()));
    }
    // let formattedValue = valueSelected.replaceAll('\n', '\n#@#');
    if (
      selectedClause.name.trim().length > 0 &&
      valueSelected.trim().length > 0
    ) {
      const res = isReviewer
        ? await resolve(
            api.post(
              `/reviewer/update_existing_db/${selectedClause.id}`,
              JSON.stringify({
                clause_key: selectedClause.name.trim().replaceAll(' ', '_'),
                clause_value: valueSelected,
                clause_id: selectedClause.id,
                contract_type: types ? types : formattedArr.join(','),
              }),
              {
                headers: {
                  'Content-Type': 'application/json',
                },
              }
            )
          )
        : await resolve(
            api.post(
              `/reviewer/save_user_clause/${selectedClause.id}`,
              JSON.stringify({
                clause_key: selectedClause.name.trim().replaceAll(' ', '_'),
                clause_value: valueSelected,
                clause_id: selectedClause.id,
              }),
              {
                headers: {
                  'Content-Type': 'application/json',
                },
              }
            )
          );
      if (res.data?.data) {
        setTextSearched(false);
        setCurrentClauseKey(selectedClause.name.trim().replaceAll(' ', '_'));
        getLatestClauses({ clauseId: selectedClause.id, isPrimary: false });
        setIsSaveOpen(true);
        setCurrentClauseValue('');
        setValueSelected('');
        setTitle('Save Clause');
        setBodyText('Clause values saved');
        checkIsDiscardAvailable(selectedClause.id);
      } else {
        if (res.error?.response.data.code === 'duplicate_clause_key') {
          setIsSaveOpen(true);
          setTitle('Clause already exists ');
          setBodyText('Clause was not added. Add a new one');
        } else {
          setIsSaveOpen(true);
          setTitle('Something went wrong');
          setBodyText('Please try again.');
        }
      }
    }
    setLoading(false);
  };
  const getNextDoc = async () => {
    setIsEditing(false);
    setLoading(true);
    const res = await resolve(
      api.get(
        `/reviewer/get_next_contract/${reviewSelection.id}/${selectedContractType.value}`
      )
    );
    if (res.data) {
      const { contract } = res.data.data;
      setReviewSelection({
        ...reviewSelection,
        file_url: contract.file_url,
        doc_name: contract.doc_name,
        converted_file_url: contract.converted_file_url,
        id: contract.id,
      });
    }
    setLoading(false);
  };
  const getPrevDoc = async () => {
    setIsEditing(false);
    setLoading(true);
    const res = await resolve(
      api.get(
        `/reviewer/get_prev_contract/${reviewSelection.id}/${selectedContractType.value}`
      )
    );
    if (res.data) {
      const { contract } = res.data.data;
      setReviewSelection({
        ...reviewSelection,
        file_url: contract.file_url,
        doc_name: contract.doc_name,
        converted_file_url: contract.converted_file_url,
        id: contract.id,
      });
    }
    setLoading(false);
  };

  const discardChanges = async () => {
    setTextSearched(false);
    setLoading(true);
    const res = await resolve(
      api.get(`/reviewer/discard_contract_changes/${selectedClause.id}`)
    );
    if (res.data) {
      setShowDiscardBtn(false);
      getLatestClauses({ clauseId: selectedClause.id, isPrimary: false });
      setCurrentClauseKey(selectedClause.name.trim().replaceAll(' ', '_'));
      checkIsDiscardAvailable(selectedClause.id);
    }
    setIsDeleteModalOpen(false);
    setLoading(false);
  };

  const getContractTypes = async (id) => {
    if (user.user_type === 'reviewer') {
      const res = await resolve(api.get(`/reviewer/get_contract_type/${id}`));
      if (res.data) {
        const typesArr = res.data.data.contract_type.toLowerCase().split(',');
        const formattedArr = [];
        typesArr.forEach((val) =>
          formattedArr.push({ value: val, label: val })
        );
        setDefaultTypes([...formattedArr]);
        setTypesLoaded(true);
      }
      setTypesLoaded(true);
    }
  };
  const checkIsDiscardAvailable = async (id) => {
    const res = await resolve(api.get(`/reviewer/discard_available/${id}`));
    if (res.data?.data) {
      if (res.data.data.available) {
        setShowDiscardBtn(true);
      }
    }
  };
  useEffect(() => {
    const isHighlightTokenExpired =
      dayjs.unix(highlightToken.expiry).diff(dayjs()) < 1;
    if (isHighlightTokenExpired) {
      getHighlightSasToken();
    }
    getClauseValues();
    getLatestClauses({ clauseId: null, isPrimary: true });
  }, [reviewSelection]);
  useEffect(() => {
    return () => {
      stop();
      setSelectedContractType(null);
    };
  }, []);
  useEffect(() => {
    if (contractClauses.length > 0) {
      getHighlightedDoc({
        id: reviewSelection.id,
        clause: currentClauseKey
          ? [currentClauseKey]
          : [contractClauses[0].name.trim().replaceAll(' ', '_')],
      });
      setCurrentClauseKey(null);
    }
    getContractTypes(reviewSelection.id);
  }, [contractClauses]);

  useEffect(() => {
    let hubspot = document.querySelector('#hubspot-messages-iframe-container');
    if (hubspot) {
      hubspot.style.visibility = 'hidden';
    }
    const listenerFunc = (e) => e.preventDefault();
    window.addEventListener('copy', listenerFunc);
    window.addEventListener('contextmenu', listenerFunc);
    return () => {
      if (hubspot) {
        hubspot.style.visibility = 'visible';
      }
      window.removeEventListener('contextmenu', listenerFunc);
      window.removeEventListener('copy', listenerFunc);
    };
  });
  useEffect(() => {
    let interval;
    if (
      valueSelected.length > 0 &&
      currentClauseValue.length > 0 &&
      valueSelected !== currentClauseValue
    ) {
      interval = setInterval(
        () => saveReviewerClause(user.user_type === 'reviewer'),
        120000 //2mins
      );
    }
    return () => clearInterval(interval);
  }, [valueSelected]);

  if (loading || dockOpened) {
    return <LoadingScreen />;
  }
  return (
    <div className='text-primary'>
      <h1 className='text-4xl font-bold'>Review</h1>
      <div className='mt-3 flex justify-between'>
        {/* <Select
          options={contractTypes}
          placeholder='Contract Type'
          styles={dropDownStyles}
          isSearchable={true}
          className='w-3/12 text-primary'
          onChange={(repo) => {}}
          defaultValue={
            selectedContractType ? selectedContractType : contractTypes[0]
          }
        /> */}
        {reviewSelection.doc_name && (
          <p className='text-lg font-normal'>
            Contract Name:{' '}
            {`${reviewSelection.doc_name[0].toUpperCase()}${reviewSelection.doc_name
              .trim()
              .toLowerCase()
              .replaceAll(format, ' ')
              .replaceAll(/(pdf|doc)/g, '')
              .slice(1)}`}
          </p>
        )}
        <button
          className='bg-primary text-white p-1 px-3 ml-2 rounded-lg'
          onClick={() => setIsOpen(true)}
        >
          <span className='flex'>
            <img src={Group} width={20} className='rounded-md' />
            <span className='font-semibold ml-2'>Add New Clause</span>
          </span>
        </button>
      </div>
      {/* <div className='mt-3 rounded-lg'>
        {contracts.length < 1 && (
          <p className='text-red-500 text-sm font-medium'>{errMsg}</p>
        )}
      </div> */}
      <div className='mt-3 '>
        {contractClauses.length > 0 && (
          <div className='border-2 border-primary overflow-auto rounded-md'>
            <ul className='p-2 grid grid-cols-4 max-h-52 gap-1'>
              {contractClauses &&
                contractClauses.map((clause, idx) => (
                  <ClauseItem
                    selectedId={selectedId}
                    setIsEditing={setIsEditing}
                    setSelectedId={setSelectedId}
                    setSelectedClause={setSelectedClause}
                    selectedClause={selectedClause}
                    id={idx}
                    clause={clause}
                    reviewSelection={reviewSelection}
                    getHighlightedDoc={getHighlightedDoc}
                    setShowDiscardBtn={setShowDiscardBtn}
                    ref={ref}
                    getLatestClauses={getLatestClauses}
                    setValueSelected={setValueSelected}
                    setCurrentClauseValue={setCurrentClauseValue}
                    setTextSearched={setTextSearched}
                    checkIsDiscardAvailable={checkIsDiscardAvailable}
                  />
                ))}
            </ul>
          </div>
        )}
        <div className='my-2'>
          <div className='flex w-full items-center'>
            {pagination.prev && (
              <button
                style={{ marginLeft: -25 }}
                onClick={() => getPrevDoc()}
                data-tip='Previous Contract'
              >
                <img src={PrevRounded} />
              </button>
            )}
            <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
            <div
              style={{
                height: 500,
                border: '1px solid #400835',
                borderRadius: 2,
                width: '100%',
                margin: '0 0.5rem',
                padding: 1,
              }}
            >
              <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js'>
                {pdfLoading ? (
                  <LoadingScreen />
                ) : (
                  <>
                    {isEditing && (
                      <TextSelector
                        events={[
                          {
                            text: 'Select',
                            handler: (html, text) => {
                              let formattedText = '';
                              if (html.children.length > 0) {
                                for (let item of html.children) {
                                  if (item.localName !== 'br')
                                    formattedText += `${item.innerText}`;
                                  else formattedText += '\n';
                                }
                              } else {
                                formattedText = text;
                              }
                              if (user.user_type === 'reviewer') {
                                let partyClauseList =
                                  disabledClauses.party &&
                                  disabledClauses.party.split('#@#');
                                let partyClauseStr = '';
                                partyClauseList &&
                                  partyClauseList.forEach((str) => {
                                    if (str.length > 0) {
                                      const s = str
                                        .replaceAll('\n', '')
                                        .replaceAll(
                                          /\b(?:a|an|the|of|and|to|in|their)\b/gi,
                                          ''
                                        );
                                      partyClauseStr += s;
                                    }
                                  });
                                let partyClauseSplitArr =
                                  partyClauseStr &&
                                  partyClauseStr.toLowerCase().split(' ');
                                let excecutionClauseList =
                                  disabledClauses.execution &&
                                  disabledClauses.execution.split('#@#');
                                let executionClauseStr = '';
                                excecutionClauseList &&
                                  excecutionClauseList.forEach((str) => {
                                    if (str.length > 0) {
                                      const s = str
                                        .replaceAll('\n', '')
                                        .replaceAll(
                                          /\b(?:a|an|the|of|and|to|in|their)\b/gi,
                                          ''
                                        );
                                      executionClauseStr += s;
                                    }
                                  });
                                let executionClauseSplitArr =
                                  executionClauseStr &&
                                  executionClauseStr.toLowerCase().split(' ');
                                let selectedTextSplitArr = text
                                  .replaceAll(
                                    /\b(?:a|an|the|of|and|to|in|their)\b/gi,
                                    ''
                                  )
                                  .toLowerCase()
                                  .split(' ');
                                let count = 0;
                                selectedTextSplitArr.forEach((word) => {
                                  if (
                                    (word &&
                                      word.length > 0 &&
                                      word !== ',' &&
                                      partyClauseSplitArr &&
                                      partyClauseSplitArr.includes(word)) ||
                                    (word &&
                                      word.length > 0 &&
                                      word !== ',' &&
                                      executionClauseSplitArr &&
                                      executionClauseSplitArr.includes(word))
                                  )
                                    count++;
                                });

                                if (
                                  count < 10 &&
                                  text.split(' ').length >= 10
                                ) {
                                  setValueSelected(formattedText);
                                }
                              } else {
                                setValueSelected(formattedText);
                              }
                            },
                          },
                          {
                            text: 'Append',
                            handler: (html, text) => {
                              let formattedText = '';
                              if (html.children.length > 0) {
                                for (let item of html.children) {
                                  if (item.localName !== 'br')
                                    formattedText += `${item.innerText}`;
                                  else formattedText += '\n';
                                }
                              } else {
                                formattedText = text;
                              }
                              let newVal =
                                valueSelected.length > 0
                                  ? valueSelected + '\n' + '#@#' + formattedText
                                  : '#@#' + formattedText;
                              if (user.user_type === 'reviewer') {
                                let partyClauseList =
                                  disabledClauses.party &&
                                  disabledClauses.party.split('#@#');
                                let partyClauseStr = '';
                                partyClauseList &&
                                  partyClauseList.forEach((str) => {
                                    if (str.length > 0) {
                                      const s = str
                                        .replaceAll('\n', '')
                                        .replaceAll(
                                          /\b(?:a|an|the|of|and|to|in|their)\b/gi,
                                          ''
                                        );
                                      partyClauseStr += s;
                                    }
                                  });
                                let partyClauseSplitArr =
                                  partyClauseStr &&
                                  partyClauseStr.toLowerCase().split(' ');
                                let excecutionClauseList =
                                  disabledClauses.execution &&
                                  disabledClauses.execution.split('#@#');
                                let executionClauseStr = '';
                                excecutionClauseList &&
                                  excecutionClauseList.forEach((str) => {
                                    if (str.length > 0) {
                                      const s = str
                                        .replaceAll('\n', '')
                                        .replaceAll(
                                          /\b(?:a|an|the|of|and|to|in|their)\b/gi,
                                          ''
                                        );
                                      executionClauseStr += s;
                                    }
                                  });
                                let executionClauseSplitArr =
                                  executionClauseStr &&
                                  executionClauseStr.toLowerCase().split(' ');
                                let selectedTextSplitArr = text
                                  .replaceAll(
                                    /\b(?:a|an|the|of|and|to|in|their)\b/gi,
                                    ''
                                  )
                                  .toLowerCase()
                                  .split(' ');
                                let count = 0;
                                selectedTextSplitArr.forEach((word) => {
                                  if (
                                    (word &&
                                      word.length > 0 &&
                                      word !== ',' &&
                                      partyClauseSplitArr &&
                                      partyClauseSplitArr.includes(word)) ||
                                    (word &&
                                      word.length > 0 &&
                                      word !== ',' &&
                                      executionClauseSplitArr &&
                                      executionClauseSplitArr.includes(word))
                                  )
                                    count++;
                                });

                                if (count < 10 && text.split(' ').length >= 10)
                                  setValueSelected(newVal);
                              } else {
                                setValueSelected(newVal);
                              }
                            },
                          },
                          {
                            text: 'Clear',
                            handler: () => {
                              setValueSelected('');
                            },
                          },
                        ]}
                      />
                    )}
                    {docUrl && (
                      <Viewer
                        fileUrl={`${docUrl}?${highlightToken.sas_token}`}
                        // fileUrl={'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf'}
                        plugins={[
                          defaultLayoutPluginInstance,
                          searchPluginInstance,
                        ]}
                        renderError={(e) => (
                          <div className='grid place-items-center h-full w-full'>
                            <h1 className='font-semibold'>
                              Contract indexing in progress. Check back later
                            </h1>
                          </div>
                        )}
                        renderLoader={() => <LoadingScreen />}
                      />
                    )}
                  </>
                )}
              </Worker>
            </div>
            {pagination.next && (
              <>
                <button onClick={() => getNextDoc()} data-tip='Next Contract'>
                  {' '}
                  <img src={NextRounded} />
                </button>
                <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
              </>
            )}
          </div>
        </div>
        <div className='flex justify-between items-end'>
          <div className='w-2/3'>
            {contractClauses.length > 0 && (
              <>
                <div className='mb-2  flex flex-col mx-2 '>
                  <label className='font-semibold'>Clause Name</label>
                  <div
                    className={`flex w-1/2 border-2 border-primary p-1 rounded-md justify-between ${
                      inputDisabled ? 'bg-slate-200' : ''
                    }`}
                  >
                    <input
                      className={`focus:outline-none p-0.5 ${
                        inputDisabled ? 'bg-slate-200' : ''
                      }`}
                      style={{ width: '90%' }}
                      value={selectedClause.name
                        .toLowerCase()
                        .replaceAll('clause', '')
                        .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                          letter.toUpperCase()
                        )}
                      onChange={(e) =>
                        setSelectedClause({
                          ...selectedClause,
                          name: e.target.value,
                        })
                      }
                      disabled={inputDisabled}
                      onBlur={() => setInputDisabled(true)}
                    />
                    <span
                      className='cursor-pointer'
                      onClick={() => {
                        setInputDisabled(false);
                        setIsEditing(true);
                      }}
                    >
                      <svg
                        fill='#000000'
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 48 48'
                        width='25px'
                        height='25px'
                      >
                        <path d='M 36 5.0097656 C 34.205301 5.0097656 32.410791 5.6901377 31.050781 7.0507812 L 8.9160156 29.183594 C 8.4960384 29.603571 8.1884588 30.12585 8.0253906 30.699219 L 5.0585938 41.087891 A 1.50015 1.50015 0 0 0 6.9121094 42.941406 L 17.302734 39.974609 A 1.50015 1.50015 0 0 0 17.304688 39.972656 C 17.874212 39.808939 18.39521 39.50518 18.816406 39.083984 L 40.949219 16.949219 C 43.670344 14.228094 43.670344 9.7719064 40.949219 7.0507812 C 39.589209 5.6901377 37.794699 5.0097656 36 5.0097656 z M 36 7.9921875 C 37.020801 7.9921875 38.040182 8.3855186 38.826172 9.171875 A 1.50015 1.50015 0 0 0 38.828125 9.171875 C 40.403 10.74675 40.403 13.25325 38.828125 14.828125 L 36.888672 16.767578 L 31.232422 11.111328 L 33.171875 9.171875 C 33.957865 8.3855186 34.979199 7.9921875 36 7.9921875 z M 29.111328 13.232422 L 34.767578 18.888672 L 16.693359 36.962891 C 16.634729 37.021121 16.560472 37.065723 16.476562 37.089844 L 8.6835938 39.316406 L 10.910156 31.521484 A 1.50015 1.50015 0 0 0 10.910156 31.519531 C 10.933086 31.438901 10.975086 31.366709 11.037109 31.304688 L 29.111328 13.232422 z' />
                      </svg>
                    </span>
                  </div>
                </div>
                {user.user_type === 'reviewer' && (
                  <div className='mb-2  flex flex-col mx-2 '>
                    <label className='font-semibold'>Contract Type</label>
                    {typesLoaded && (
                      <Creatable
                        options={contractTypes}
                        placeholder='Select Contract Type'
                        styles={customStyles}
                        isMulti={true}
                        isSearchable={true}
                        onChange={(arr) => {
                          let val = [];
                          arr.forEach((obj) =>
                            val.push(obj.value.toLowerCase())
                          );
                          setTypes(val.join(','));
                        }}
                        isClearable={false}
                        className='w-2/3 text-primary'
                        defaultValue={defaultTypes}
                        isDisabled={!isEditing}
                      />
                    )}
                    {/* <span
                      className='cursor-pointer'
                      onClick={() => {
                        setInputDisabled(false);
                        setIsEditing(true);
                      }}
                    >
                      <svg
                        fill='#000000'
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 48 48'
                        width='25px'
                        height='25px'
                      >
                        <path d='M 36 5.0097656 C 34.205301 5.0097656 32.410791 5.6901377 31.050781 7.0507812 L 8.9160156 29.183594 C 8.4960384 29.603571 8.1884588 30.12585 8.0253906 30.699219 L 5.0585938 41.087891 A 1.50015 1.50015 0 0 0 6.9121094 42.941406 L 17.302734 39.974609 A 1.50015 1.50015 0 0 0 17.304688 39.972656 C 17.874212 39.808939 18.39521 39.50518 18.816406 39.083984 L 40.949219 16.949219 C 43.670344 14.228094 43.670344 9.7719064 40.949219 7.0507812 C 39.589209 5.6901377 37.794699 5.0097656 36 5.0097656 z M 36 7.9921875 C 37.020801 7.9921875 38.040182 8.3855186 38.826172 9.171875 A 1.50015 1.50015 0 0 0 38.828125 9.171875 C 40.403 10.74675 40.403 13.25325 38.828125 14.828125 L 36.888672 16.767578 L 31.232422 11.111328 L 33.171875 9.171875 C 33.957865 8.3855186 34.979199 7.9921875 36 7.9921875 z M 29.111328 13.232422 L 34.767578 18.888672 L 16.693359 36.962891 C 16.634729 37.021121 16.560472 37.065723 16.476562 37.089844 L 8.6835938 39.316406 L 10.910156 31.521484 A 1.50015 1.50015 0 0 0 10.910156 31.519531 C 10.933086 31.438901 10.975086 31.366709 11.037109 31.304688 L 29.111328 13.232422 z' />
                      </svg>
                    </span> */}
                    {/* </div> */}
                  </div>
                )}
              </>
            )}
            <textarea
              className='w-full h-28 focus:outline-none border-2 border-primary rounded-sm ml-2'
              // style={{ resize: 'none' }}
              ref={ref}
              // value={valueSelected.replaceAll('#@#', '\n')}
              value={valueSelected.replaceAll('#@#', '')}
              disabled
            />
          </div>
          {isEditing && (
            <div className='w-60'>
              {/* {user.user_type === 'reviewer' && (
                <select
                  className='w-full focus:outline-none border-2 border-primary rounded-md p-1 mt-4'
                  // value={saveOption}
                  onChange={(e) => setSaveOption(e.target.value)}
                >
                  <option className='cursor-pointer'>
                    Save clause to an existing DB
                  </option>
                  <option className='cursor-pointer'>
                    Save clause to a new DB
                  </option>
                </select>
              )} */}
              <button
                className='bg-primary w-60 text-white rounded-md px-2 py-1 mb-2 font-semibold text-lg'
                onClick={() => {
                  saveReviewerClause(user.user_type === 'reviewer');
                }}
              >
                Save
              </button>
              {showDiscardBtn && (
                <button
                  className='bg-secondary w-60 text-white rounded-md px-2 py-1 mb-2 font-semibold'
                  onClick={() => setIsDeleteModalOpen(true)}
                >
                  Discard
                </button>
              )}
            </div>
          )}
        </div>
      </div>
      <AddClause
        getClauses={getLatestClauses}
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        contract_id={reviewSelection.id}
        selectedClause={selectedClause}
        setCurrentClauseKey={setCurrentClauseKey}
        checkIsDiscardAvailable={checkIsDiscardAvailable}
      />
      <AppModal
        title={title}
        bodyText={bodyText}
        modalIsOpen={saveModalOpen}
        setIsOpen={setIsSaveOpen}
      />
      <DeleteModal
        title='Discard Changes'
        bodyText='This will discard previous changes'
        isDeleteModalOpen={isDeleteModalOpen}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        discardChanges={discardChanges}
      />
    </div>
  );
};

export default DetailedComponent;
