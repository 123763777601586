import React, { useMemo, useState, useContext, useEffect } from 'react';
import CommonModuleUI from './CommonComponentUI';
import AppContext from '../../context/AppContext';
import LoadingScreen from '../loader/LoadingScreen';

const CommonModule = ({
  heading,
  isCompany,
  inputPlaceholder,
  addBtnText,
  errMsg,
  moduleData,
  moduleTableColumns,
  currentPage,
  setCurrentPage,
  nextPage,
  prevPage,
  getSearchData,
  activeLinkTitle,
  pageLoading,
  sortingOrder,
  setSortingOrder,
  setSortColumn,
  getModuleData,
}) => {
  const { companiesList, getCompanies } = useContext(AppContext);
  const data = useMemo(() => [...moduleData], [moduleData]);
  const columns = useMemo(() => [...moduleTableColumns], [moduleTableColumns]);

  useEffect(() => {
    getCompanies();
  }, []);
  if (pageLoading) {
    return <LoadingScreen />;
  }

  return (
    <CommonModuleUI
      moduleTableColumns={columns}
      moduleData={data}
      nextPage={nextPage}
      prevPage={prevPage}
      heading={heading}
      isCompany={isCompany}
      inputPlaceholder={inputPlaceholder}
      addBtnText={addBtnText}
      errMsg={errMsg}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      getSearchData={getSearchData}
      companiesList={companiesList}
      activeLinkTitle={activeLinkTitle}
      sortingOrder={sortingOrder}
      setSortingOrder={setSortingOrder}
      setSortColumn={setSortColumn}
      getModuleData={getModuleData}
    />
  );
};

export default CommonModule;
