import React from 'react';
import { Link } from 'react-router-dom';
import Search from '../../assets/icons/Search.svg';
import AddContract from '../../assets/icons/Add-Contract.svg';
import Research from '../../assets/icons/research.png';
import User from '../../assets/icons/User.svg';

const AdminUser = ({ activeLink, setActiveLink, user, currentForm }) => {
  return (
    <>
      <li
        onClick={() => setActiveLink('Search')}
        className={
          activeLink === 'Search' || activeLink === 'ViewContract'
            ? 'active-link'
            : undefined
        }
      >
        <img src={Search} />
        <span>Search</span>
      </li>
      <li
        onClick={() => setActiveLink('AddContract')}
        className={
          activeLink === 'AddContract' || activeLink === 'ContractForm'
            ? 'active-link'
            : undefined
        }
      >
        <img src={AddContract} />
        <span>Add Contract</span>
      </li>
      {user.user_type === 'admin' && (
        <li
          onClick={() => setActiveLink('Users')}
          className={
            activeLink === 'Users' || currentForm === 'UserForm'
              ? 'active-link'
              : undefined
          }
        >
          <img src={User} />
          <span>Users</span>
        </li>
      )}
      {user.is_reviewer && (
        <li
          onClick={() => setActiveLink('Review')}
          className={
            activeLink === 'Review' || activeLink === 'DetailedReview'
              ? 'active-link'
              : undefined
          }
        >
          <img src={Research} />
          <span>Review Contracts</span>
        </li>
      )}
    </>
  );
};

export default AdminUser;
