import React, { useState, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { validateEmail } from '../../utils/utils';
import AuthContext from '../../context/AuthContext';
import LoginUI from './LoginUI';

const Login = ({ title }) => {
  const { loginUser, user } = useContext(AuthContext);
  const [formErrors, setFormErrors] = useState({ email: '', password: '' });
  if (user) {
    return <Navigate to='/dashboard' />;
  }
  const onSubmit = (email, password) => {
    if (!validateEmail(email)) {
      setFormErrors({ ...formErrors, email: 'Please enter a valid email!' });
    } else if (password.trim().length < 1) {
      setFormErrors({ ...formErrors, password: 'Please enter a password' });
    } else {
      loginUser(email, password);
    }
  };
  return <LoginUI title={title} formErrors={formErrors} onSubmit={onSubmit} />;
};

export default Login;
