import React, { useState } from 'react';
import Modal from 'react-modal';
import './modal.css';
import useAxios from '../../hooks/useAxios';
import { resolve } from '../../api/resolve';
const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

const customStyles = {
  content: {
    top: '40%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 300,
    maxHeight: '220px',
    borderRadius: '11px',
  },
  overlay: {
    backgroundColor: 'rgba(64, 8, 53, 0.63)',
  },
};

Modal.setAppElement('#root');
const AppModal = ({
  modalIsOpen,
  setIsOpen,
  getRepos,
  isUpdating,
  setIsUpdating,
}) => {
  const api = useAxios();
  const [errMsg, setErrMsg] = useState('');
  const [repoName, setRepoName] = useState('');
  const [validationError, setValidationError] = useState('');

  const addNewRepo = async (repoName) => {
    if (repoName.trim().length > 0) {
      if (format.test(repoName)) {
        setValidationError('Special Characters are not allowed');
      } else {
        const res = await resolve(
          api
            .post(
              `/contract/view_create_repo/`,
              JSON.stringify({ repo_name: repoName }),
              {
                headers: {
                  'content-type': 'application/json',
                },
              }
            )
            .then((res) => res.data)
        );
        if (res.data) {
          getRepos();
          setIsOpen(false);
        } else {
          setErrMsg('Repository was not added. Try again');
        }
      }
    }
  };
  return (
    <div className=''>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        contentLabel='Example Modal'
        onAfterClose={() => {
          setErrMsg('');
          setIsUpdating(false);
        }}
      >
        {errMsg ? (
          <>
            <h1 className='text-primary font-semibold text-lg'>{errMsg}</h1>
            <div className='flex justify-center mt-3'>
              <button
                type='button'
                className='bg-primary font-semibold text-white p-1 px-5 rounded-lg w-2/3'
                onClick={() => setIsOpen(false)}
              >
                Accept
              </button>
            </div>
          </>
        ) : (
          <div className='text-center modal-container'>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                addNewRepo(repoName);
              }}
            >
              <h1 className='text-primary font-bold text-lg'>
                {isUpdating ? 'Rename Repository' : 'Add New Repository'}
              </h1>
              <input
                placeholder='Repository Name'
                className='border-2 border-primary rounded-lg outline-none p-1 pl-2 w-full my-2'
                onChange={(e) => setRepoName(e.target.value)}
              />
              <p className='text-red-500 mb-2'>{validationError}</p>
              <div className='flex justify-center'>
                <button
                  type='submit'
                  className={`${
                    isUpdating ? 'bg-secondary' : 'bg-primary'
                  } font-semibold text-white p-1 px-5 rounded-lg w-2/3`}
                >
                  {isUpdating ? 'Update' : 'Save'}
                </button>
              </div>
            </form>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default AppModal;
