import React, { useState, useContext } from 'react';
import { useTable } from 'react-table';
import '../contracts/contracts.css';
import DownArrow from '../../assets/icons/down-arrow.svg';
import ReactTooltip from 'react-tooltip';
import FormContext from '../../context/FormContext';
import AppContext from '../../context/AppContext';
import AuthContext from '../../context/AuthContext';

export const NoDataComponent = ({ colLength }) => {
  const [rows] = useState(Array(4).fill(''));
  const [cols] = useState(Array(colLength).fill(''));
  return (
    <>
      {rows.map(() => (
        <tr>
          {cols.map(() => (
            <td className='px-5 py-2 font-medium text-smW'>--</td>
          ))}
        </tr>
      ))}
    </>
  );
};

const AdminTable = ({
  contractsData,
  contractTableColumns,
  prevPage,
  nextPage,
  currentPage,
  setCurrentPage,
  selectedContract,
  setSelectedContract,
  sortingOrder,
  setSortingOrder,
  setSortColumn,
  pageLimit,
  setPageLimit,
  contractCount,
  setContractCount,
  showActionBtn,
  showPageLimit,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: contractTableColumns, data: contractsData });
  const { setEditFormData, setIsEditing } = useContext(FormContext);
  const [currentRow, setCurrentRow] = useState(0);
  const { setActiveLink, reviewSelection, setReviewSelection } =
    useContext(AppContext);
  const { user } = useContext(AuthContext);

  return (
    <>
      {contractsData.length > 0 && (
        <div
          className={`mt-3 flex ${
            showPageLimit ? 'justify-between' : 'justify-end'
          }`}
        >
          {showPageLimit && (
            <div>
              {contractCount > 5 && (
                <>
                  <span>Show</span>
                  <select
                    className='border-2 border-primary outline-none mx-1 rounded-md cursor-pointer'
                    onChange={(e) => setPageLimit(e.target.value)}
                  >
                    <option selected>5</option>
                    {/* {contractCount > 5 && <option>10</option>}
                  {contractCount > 10 && <option>15</option>}
                  {contractCount > 15 && <option>25</option>} */}
                    {contractCount >= 10 && <option>10</option>}
                    {contractCount >= 15 && <option>15</option>}
                    {contractCount >= 25 && <option>25</option>}
                  </select>
                  <span>Results</span>
                </>
              )}
            </div>
          )}
          <div className='flex text-primary  border-t-2 border-b-2 border-primary rounded-lg'>
            <button
              className={`pl-5 pr-3 border-r-2 border-l-2 border-primary font-semibold rounded-tl-lg rounded-bl-lg ${
                !prevPage && 'bg-inputBackground'
              }`}
              disabled={!prevPage}
              onClick={(e) => {
                setCurrentRow(0);
                if (e.detail == 1) setCurrentPage(currentPage - 1);
              }}
            >
              Prev
            </button>
            <span className='px-2 font-semibold'>{currentPage}</span>
            <button
              className={`pr-5 pl-3 border-l-2 border-r-2 border-primary font-semibold rounded-tr-lg rounded-br-lg ${
                !nextPage && 'bg-inputBackground'
              }`}
              disabled={!nextPage}
              onClick={(e) => {
                setCurrentRow(0);
                if (e.detail == 1) setCurrentPage(currentPage + 1);
              }}
            >
              Next
            </button>
          </div>
        </div>
      )}
      <div className='mt-2 flex flex-col table-container mb-5'>
        <div>
          <div>
            <div className='shadow overflow-hidden border-2 border-primary  sm:rounded-lg max-h-96 overflow-y-auto'>
              <table {...getTableProps()} className='w-full '>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props

                        <th
                          scope='col'
                          className='text-left px-2 pt-2'
                          {...column
                            .getHeaderProps
                            // column.getSortByToggleProps()
                            ()}
                        >
                          <div className='flex items-center'>
                            <span>{column.render('Header')}</span>
                            {/* Add a sort direction indicator */}
                            {sortingOrder === 'desc' ? (
                              <span
                                onClick={(e) => {
                                  setSortingOrder('asc');
                                  setSortColumn(column.id);
                                }}
                                style={{ cursor: 'pointer', marginTop: 2 }}
                                className='ml-1'
                              >
                                {/* &#11165; */}
                                <img src={DownArrow} />
                              </span>
                            ) : (
                              <span
                                onClick={(e) => {
                                  setSortingOrder('desc');
                                  setSortColumn(`-${column.id}`);
                                }}
                                style={{
                                  cursor: 'pointer',
                                  transform: 'rotate(180deg)',
                                }}
                                className='ml-1'
                              >
                                <img src={DownArrow} />
                              </span>
                            )}
                          </div>
                        </th>
                      ))}
                      {showActionBtn && (
                        <th scope='col' className='text-left px-4 pt-2'>
                          Action
                        </th>
                      )}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {contractsData.length < 1 && (
                    <NoDataComponent colLength={contractTableColumns.length} />
                  )}
                  {contractsData.length > 0 &&
                    rows.map((row, i) => {
                      // new
                      prepareRow(row);
                      return (
                        <tr
                          {...row.getRowProps()}
                          onClick={() => {
                            setCurrentRow(i);
                            user.user_type !== 'reviewer' &&
                              setSelectedContract({
                                ...selectedContract,
                                doc_name: row.original.doc_name,
                                file_url: row.original.file_url,
                                converted_file_url:
                                  row.original.converted_file_url,
                                id: row.original.id,
                              });
                          }}
                          className={`cursor-pointer ${
                            currentRow === i && 'bg-[#F6C107]'
                          }`}
                        >
                          {row.cells.map((cell) => {
                            return (
                              <>
                                <td
                                  {...cell.getCellProps()}
                                  className={`px-2 py-2 font-medium text-sm contracts-col-data ${
                                    cell.value === 'In-Active' && 'text-red-500'
                                  }`}
                                  style={
                                    cell.column.Header === 'Contract Name'
                                      ? {
                                          width: 220,
                                          overflow: 'hidden',
                                          display: 'block',
                                          textOverflow: 'ellipsis',
                                        }
                                      : null
                                  }
                                >
                                  <span
                                    data-tip={
                                      cell.column.Header === 'Contract Name' &&
                                      cell.value.length > 20
                                        ? cell.value
                                        : null
                                    }
                                  >
                                    {cell.render('Cell')}
                                  </span>
                                  {cell.column.Header === 'Contract Name' && (
                                    <ReactTooltip
                                      backgroundColor='#400835'
                                      textColor='#ffffff'
                                      className='max-w-lg break-words'
                                    />
                                  )}
                                </td>
                              </>
                            );
                          })}
                          {showActionBtn && (
                            <td className='py-2'>
                              <button
                                className='bg-secondary text-white  p-1 px-4 text-sm font-bold rounded-lg'
                                onClick={() => {
                                  const { original } = row;
                                  setReviewSelection({
                                    ...reviewSelection,
                                    file_url: original.file_url,
                                    doc_name: original.doc_name,
                                    converted_file_url:
                                      original.converted_file_url,
                                    id: original.id,
                                  });
                                  setActiveLink('DetailedReview');
                                }}
                              >
                                Review
                              </button>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminTable;
