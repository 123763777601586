import React, { useEffect, useState, useContext } from 'react';
import AppContext from '../../../context/AppContext';
import useAxios from '../../../hooks/useAxios';
import { resolve } from '../../../api/resolve';

const TrainingSteps = ({ setTitle, setBodyText, setIsOpen }) => {
  const api = useAxios();
  const { setActiveLink, modelID } = useContext(AppContext);
  const [currentStep, setCurrentStep] = useState({ code: '', name: '' });
  const [count, setCount] = useState(0);
  const [steps, setSteps] = useState([
    'Reading and validating Data',
    'Training Model',
    'Generating Results',
    'Finalizing',
  ]);

  const getProgress = async () => {
    setCount(count + 1);
    const res = await resolve(
      api
        .get(`/reviewer/get_training_progress/${modelID}`)
        .then((res) => res.data)
    );
    if (res.data) {
      setCurrentStep({
        ...currentStep,
        code: res.data.code,
        name: res.data.msg,
      });
    } else {
      setIsOpen(true);
      setTitle('Training Error');
      setBodyText('There was a problem while training');
    }
  };
  let timeout;
  useEffect(() => {
    if (modelID) {
      if (currentStep.code === 'completed') {
        clearTimeout(timeout);
        setActiveLink('TrainingResults');
      } else {
        timeout = setTimeout(() => {
          getProgress();
        }, 30000);
      }
    }
    return () => clearTimeout(timeout);
  }, [modelID, count]);
  useEffect(() => {
    if (modelID) {
      getProgress();
    }
  }, [modelID]);
  return (
    <div>
      <p>Training under process. Please do not refresh the browser.</p>
      {steps.map((step, idx) => (
        <p
          className={`text-lg font-semibold mt-2 ${
            idx === 3
              ? 'text-gray-400'
              : idx < count || count === 3
              ? 'text-primary'
              : 'text-gray-400'
          }`}
          //   className={`text-lg font-semibold text-primary `}
        >
          <span>
            {`${idx + 1})`}{' '}
            {idx === count && count < 3 ? `${step} ...` : `${step}`}
            {/* {`${step} ...`} */}
          </span>
          {idx < count && idx !== 3 && <span className='ml-5'>&#x2713;</span>}
          {/* {idx < count && <span className='ml-5'>&#x2713;</span>} */}
        </p>
      ))}
    </div>
  );
};

export default TrainingSteps;
