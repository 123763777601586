import React, { useState, forwardRef } from 'react';
import { formatSelectedClause } from '../../utils/utils';
import { resolve } from '../../api/resolve';
import useAxios from '../../hooks/useAxios';
import EditIcon from '../../assets/icons/Edit.svg';
import DeleteIcon from '../../assets/icons/Delete.svg';
import DeleteModal from '../modals/DeleteModal';

const ClauseItem = forwardRef(
  (
    {
      id,
      selectedId,
      setSelectedId,
      clause,
      getHighlightedDoc,
      reviewSelection,
      setSelectedClause,
      selectedClause,
      setIsEditing,
      setShowDiscardBtn,
      getLatestClauses,
      setValueSelected,
      setTextSearched,
      checkIsDiscardAvailable,
      setCurrentClauseValue,
    },
    ref
  ) => {
    const api = useAxios();
    const [isVisible, setIsVisible] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const deleteClause = async () => {
      const res = await resolve(
        api.get(`/reviewer/delete_clause/${clause.id}`)
      );
      if (res.data) {
        setTextSearched(false);
        getLatestClauses({ clauseId: null, isPrimary: true });
      }
      setIsDeleteModalOpen(false);
    };
    return (
      <li
        onClick={() => {
          setCurrentClauseValue('');
          setValueSelected('');
          setTextSearched(false);
          setIsEditing(false);
          setShowDiscardBtn(false);
          setSelectedId(id);
          setSelectedClause({
            ...selectedClause,
            id: clause.id,
            name: clause.name,
          });
          getHighlightedDoc({
            id: reviewSelection.id,
            clause: [clause.name.trim().replaceAll(' ', '_')],
          });
          checkIsDiscardAvailable(clause.id);
        }}
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
        style={{
          boxShadow: '0 0 1px #000',
          height: '100px !important',
          display: 'block !important',
        }}
        className={`flex w-full justify-between  items-center ${
          id === selectedId || selectedClause.id === clause.id
            ? 'bg-bgDropdown'
            : ''
        }  h-10 hover:bg-bgDropdown cursor-pointer rounded-sm `}
      >
        <span className='w-3/4'>{formatSelectedClause(clause.name)}</span>
        {isVisible && (
          <button className='bg-secondary px-1 w-14 py-1 rounded-md flex justify-between text-white'>
            <img
              src={EditIcon}
              width={20}
              onClick={(e) => {
                e.stopPropagation();
                ref.current?.scrollIntoView({
                  behavior: 'smooth',
                });
                setIsEditing(true);
              }}
            />
            <img
              src={DeleteIcon}
              className='ml-0.5'
              width={20}
              onClick={(e) => {
                e.stopPropagation();
                setIsDeleteModalOpen(true);
              }}
            />
          </button>
        )}
        <DeleteModal
          title='Delete Clause'
          bodyText='This action cannot be undone. Are you sure you want to continue?'
          isDeleteModalOpen={isDeleteModalOpen}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          deleteClause={deleteClause}
        />
      </li>
    );
  }
);

export default ClauseItem;
