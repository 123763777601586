import React, { useEffect, useState, useContext } from 'react';
import dayjs from 'dayjs';
import CommonModule from './CommonComponent';
import useAxios from '../../hooks/useAxios';
import { resolve } from '../../api/resolve';
import AuthContext from '../../context/AuthContext';
import { userTableColumns } from '../../constants/constants';

const formattedColumns = userTableColumns.filter(
  (obj) => obj.Header !== 'Company'
);
const Users = () => {
  const api = useAxios();
  const { user } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [nextPage, setIsNextPage] = useState(null);
  const [prevPage, setIsPrevPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [errMsg, setErrMsg] = useState('');
  const [pageLoading, setPageLoading] = useState(true);
  const [sortingOrder, setSortingOrder] = useState('desc');
  const [sortColumn, setSortColumn] = useState('created_at');

  const getUsers = async () => {
    const res = await resolve(
      api
        .get(`/user/view_create/?ordering=${sortColumn}&page=${currentPage}`)
        .then((res) => res.data)
    );
    if (res.data) {
      const { results, next, previous } = res.data;
      const filterData = [];
      if (results.length > 0) {
        results.forEach((result) => {
          const userObj = {
            first_name: result.first_name,
            dateCreated: dayjs(result.created_at).format('D MMMM YYYY'),
            status: result.is_active_user ? 'Active' : 'In-Active',
            email: result.email,
            confirm_email: result.email,
            company_id: result.company_id,
            company_name: result.company_name,
            id: result.id,
            is_active_user: result.is_active_user,
            is_reviewer: result.is_reviewer,
            phone: result.phone,
            position: result.position,
          };
          filterData.push(userObj);
        });
      } else {
        setErrMsg('No users added yet');
      }
      setUsers([...filterData]);
      setIsNextPage(next);
      setIsPrevPage(previous);
    }
    setPageLoading(false);
  };

  const searchUser = async (searchText, companyID) => {
    if (searchText.trim().length > 0) {
      const res = await resolve(
        api
          .get(
            `/user/view_create/?company_id=${
              companyID ? companyID : ''
            }&search=${searchText}`
          )
          .then((res) => res.data)
      );
      if (res.data) {
        const { results, next, previous } = res.data;
        const filterData = [];
        if (results.length > 0) {
          results.forEach((result) => {
            const userObj = {
              first_name: result.first_name,
              dateCreated: dayjs(result.created_at).format('D MMMM YYYY'),
              status: result.is_active_user ? 'Active' : 'In-Active',
              email: result.email,
              confirm_email: result.email,
              company_id: result.company_id,
              company_name: result.company_name,
              id: result.id,
              is_active_user: result.is_active_user,
              is_reviewer: result.is_reviewer,
              phone: result.phone,
              position: result.position,
            };
            filterData.push(userObj);
          });
        } else {
          setErrMsg('No user found');
        }
        setUsers([...filterData]);
        setIsNextPage(next);
        setIsPrevPage(previous);
      }
    } else {
      getUsers();
    }
  };
  useEffect(() => {
    getUsers();
  }, [currentPage, sortColumn]);
  return (
    <CommonModule
      heading='Users'
      inputPlaceholder='Enter user name here'
      addBtnText='Add New User'
      errMsg={errMsg}
      moduleData={users}
      moduleTableColumns={
        user.user_type === 'super_admin' ? userTableColumns : formattedColumns
      }
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      isCompany={false}
      nextPage={nextPage}
      prevPage={prevPage}
      getSearchData={searchUser}
      activeLinkTitle='UserForm'
      pageLoading={pageLoading}
      sortingOrder={sortingOrder}
      setSortingOrder={setSortingOrder}
      setSortColumn={setSortColumn}
      getModuleData={getUsers}
    />
  );
};

export default Users;
