import React, { useEffect, useContext, useState } from 'react';
import './dashboard.css';
import { useIdleTimer } from 'react-idle-timer';
import { appName } from '../../constants/constants';
import AuthContext from '../../context/AuthContext';
import AppContext from '../../context/AppContext';
import FormContext from '../../context/FormContext';
import Navbar from '../navbar/Navbar';
import BellComponent from '../notifications/BellComponent';
import HomePage from '../homepage/Homepage';
import Companies from '../all-modules/Companies';
import Admins from '../all-modules/Admins';
import Users from '../all-modules/Users';
import CompanyForm from '../forms/CompanyForm';
import AdminForm from '../forms/AdminForm';
import UserForm from '../forms/UserForm';
import SearchContractComponent from '../contracts/SearchContract';
import RepoContainer from '../contracts/RepoContainer';
import ContractForm from '../contracts/ContractForm';
import NotFoundComponent from '../not-found/NotFoundComponent';
import Notifications from '../notifications/Notifications';
import HelpModule from '../help-module/HelpModule';
import Account from '../settings/Account';
import AdminClauses from '../admin-dashboard/AdminClauses';
import Reviewers from '../all-modules/Reviewers';
import ReviewerForm from '../forms/ReviewerForm';
import DetailedComponent from '../admin-dashboard/DetailedComponent';
import Training from '../admin-dashboard/admin-training/Training';
import TrainingResults from '../admin-dashboard/admin-training/TrainingResults';

const timeout = 1000 * 60 * 30;
const reviewerTimeout = 1000 * 60 * 15;

const Dashboard = ({ title }) => {
  const { activeLink, setActiveLink } = useContext(AppContext);
  const { user, logoutUser } = useContext(AuthContext);
  const { setShouldLogout } = useContext(FormContext);
  const [showBellComponent, setShowBellComponent] = useState(false);
  const idleTimer = useIdleTimer({
    onIdle: () => logoutUser(),
    timeout: user.user_type === 'reviewer' ? reviewerTimeout : timeout,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
    ],
    startOnMount: true,
    immediateEvents: [],
  });
  const handleScroll = (e) => {
    let element = e.target;
    if (element.scrollTop > 20) {
      document.querySelector('.bell-notifications-container').style.display =
        'none';
    } else {
      document.querySelector('.bell-notifications-container').style.display =
        'block';
    }
  };
  useEffect(() => {
    document.title = `${appName} | ${title}`;
    setShouldLogout(false);
    if (user.user_type === 'admin' || user.user_type === 'user') {
      setShowBellComponent(true);
    }
  }, []);
  return (
    <>
      <div className='h-screen flex'>
        <div className='navbar-container'>
          <Navbar activeLink={activeLink} setActiveLink={setActiveLink} />
        </div>
        <div
          className='dashboard-data-container p-10 pl-16 font-sans'
          onScroll={handleScroll}
        >
          {activeLink === 'Home' && <HomePage />}
          {activeLink === 'Users' && <Users />}
          {activeLink === 'Admins' && <Admins />}
          {activeLink === 'Companies' && <Companies />}
          {activeLink === 'CompanyForm' && <CompanyForm />}
          {activeLink === 'AdminForm' && <AdminForm />}
          {activeLink === 'UserForm' && <UserForm />}
          {activeLink === 'ReviewerForm' && <ReviewerForm />}
          {activeLink === 'Search' && <SearchContractComponent />}
          {activeLink === 'AddContract' && <RepoContainer />}
          {activeLink === 'ContractForm' && <ContractForm />}
          {activeLink === 'NotFound' && <NotFoundComponent />}
          {activeLink === 'Notifications' && <Notifications />}
          {activeLink === 'Help' && <HelpModule />}
          {activeLink === 'Account' && <Account />}
          {activeLink === 'Review' && <AdminClauses />}
          {activeLink === 'Reviewers' && <Reviewers />}
          {activeLink === 'DetailedReview' && <DetailedComponent />}
          {activeLink === 'Training' && <Training />}
          {activeLink === 'TrainingResults' && <TrainingResults />}
        </div>
      </div>
      {showBellComponent && <BellComponent />}
    </>
  );
};

export default Dashboard;
