import React, { useContext, useEffect, useState } from 'react';
import './notifications.css';
import AppContext from '../../context/AppContext';
import Bell from '../../assets/icons/Bell.svg';

const BellComponent = () => {
  const { setActiveLink, notificationCount, getNotificationsCount } =
    useContext(AppContext);
  useEffect(() => {
    getNotificationsCount();
  }, [notificationCount]);
  return (
    <div className='bell-notifications-container p-10'>
      <button
        className='bg-secondary pb-1 pt-0.5 pl-6 pr-6 text-white font-semibold rounded-lg flex hover:bg-[#DE3127]'
        onClick={() => setActiveLink('Notifications')}
      >
        <span>Notifications</span>
        <span className='ml-2 relative'>
          <img src={Bell} alt='Bell-icon' />
          {notificationCount > 0 && (
            <span
              className='absolute -top-3 left-3.5 bg-[#c00]'
              style={{
                minWidth: 24,
                height: 24,
                borderRadius: 50,
                padding: '0 5px',
              }}
            >
              {notificationCount}
            </span>
          )}
        </span>
      </button>
    </div>
  );
};

export default BellComponent;
