import React from 'react';
import Modal from 'react-modal';
import './modal.css';
import { formatClause } from '../../utils/utils';

const customStyles = {
  content: {
    top: '40%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 'fit-content',
    minHeight: '250px',
    borderRadius: '11px',
    overflowY: 'auto',
  },
  overlay: {
    backgroundColor: 'rgba(64, 8, 53, 0.63)',
  },
};

Modal.setAppElement('#root');
const ClausesModal = ({
  isModalOpen,
  setIsModalOpen,
  contractClauses,
  setMultiClauseData,
  getHighlightedDoc,
  selectedContract,
  multiClauseData,
  clauseList,
  setClauseList,
  setSelectedClauses,
  setDownloadDocClauses,
  downloadDocClauses,
}) => {
  return (
    <div className=''>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={customStyles}
        contentLabel='Example Modal'
        onAfterClose={() => {
          if (multiClauseData.length > 0) {
            getHighlightedDoc({
              id: selectedContract.id,
              clause: multiClauseData,
              showPrimary: true,
            });
          }
          setMultiClauseData([]);
        }}
      >
        <div className='text-center modal-container'>
          <div className='text-primary font-bold text-sm'>
            <div className='flex items-center justify-center pt-5'>
              <h2 className='mb-2 text-center'>Select Clauses</h2>
              {/* <>
                <img
                  src={HelpIcon}
                  width={12}
                  className='ml-1 -mt-2 cursor-pointer'
                  data-tip='Select single or multiple clauses'
                />
                <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
              </> */}
            </div>
            {/* <Select
              options={contractClauses}
              placeholder='Type of Clause'
              styles={{
                ...dropDownStyles,

                control: (provided, state) => {
                  return {
                    ...provided,
                    background: state.isDisabled ? '#d4c390' : '#f6d87a',
                    outline: 'none',
                    boxShadow: 'none',
                    border: '1px solid #400835',
                    cursor: 'pointer',
                    minWidth: '300px',
                    maxHeight: '120px',
                    overflowY: 'auto',
                    '&:hover': {
                      border: '1px solid #400835',
                      boxShadow: 'none',
                    },
                  };
                },
              }}
              isMulti
              isSearchable={true}
              onChange={(clauses) => {
                let formattedData = [];
                clauses.forEach((clause) =>
                  formattedData.push(clause.value.replaceAll(' ', '_'))
                );
                setMultiClauseData([...formattedData]);
              }}
              className='w-4/5 mx-auto text-primary'
              isClearable={false}
            /> */}
          </div>
          <div className='grid grid-cols-3 items-start'>
            {contractClauses.map((clause, idx) => (
              <div className='my-1 flex items-center justify-start pl-5'>
                <input
                  type='checkbox'
                  className='cursor-pointer'
                  id={`input-check-${idx}`}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setMultiClauseData([
                        ...multiClauseData,
                        clause.replaceAll(' ', '_'),
                      ]);
                      setDownloadDocClauses([
                        ...downloadDocClauses,
                        clause.replaceAll(' ', '_'),
                      ]);
                    } else {
                      let filterData = multiClauseData.filter(
                        (item) => item !== clause.replaceAll(' ', '_')
                      );
                      setMultiClauseData([...filterData]);
                      setDownloadDocClauses([...filterData]);
                    }
                  }}
                />
                <label
                  className='ml-1 cursor-pointer'
                  htmlFor={`input-check-${idx}`}
                >
                  {formatClause(clause)}
                </label>
              </div>
            ))}
          </div>
          <div className='mt-5'>
            <button
              onClick={() => {
                if (multiClauseData.length > 0) {
                  let clauses = [];
                  let selectedClauses = [];
                  multiClauseData.forEach((clause) => {
                    const val = clause.replaceAll('_', ' ');
                    clauses.push(val);
                    selectedClauses.push(clause.trim().toLowerCase());
                  });
                  setClauseList(clauses);
                  setSelectedClauses([...selectedClauses]);
                  setIsModalOpen(false);
                }
              }}
              className='bg-primary hover:bg-secondary font-semibold text-white w-1/3 p-1 rounded-lg'
            >
              Apply
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ClausesModal;
