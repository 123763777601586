import React, { useState, useContext, useEffect } from 'react';
import { useTable } from 'react-table';
import './contracts.css';
import DownArrow from '../../assets/icons/down-arrow.svg';
import ReactTooltip from 'react-tooltip';
import EditIcon from '../../assets/icons/Edit.svg';
import DeleteIcon from '../../assets/icons/Delete.svg';
import moveIcon from '../../assets/icons/send.png';
import TrashIcon from '../../assets/icons/trash.png';
import Selection from '../../assets/icons/selection.png';
import FormContext from '../../context/FormContext';
import AppContext from '../../context/AppContext';
import AuthContext from '../../context/AuthContext';
import MoveRepoModal from '../modals/MoveRepoModal';
import useAxios from '../../hooks/useAxios';
import { resolve } from '../../api/resolve';
import DeleteModal from '../modals/DeleteModal';

export const NoDataComponent = ({ colLength }) => {
  const [rows] = useState(Array(4).fill(''));
  const [cols] = useState(Array(colLength).fill(''));
  return (
    <>
      {rows.map(() => (
        <tr>
          {cols.map(() => (
            <td className='px-5 py-2 font-medium text-smW'>--</td>
          ))}
        </tr>
      ))}
    </>
  );
};

const ContractsTable = ({
  contractsData,
  contractTableColumns,
  prevPage,
  nextPage,
  currentPage,
  setCurrentPage,
  selectedContract,
  setSelectedContract,
  sortingOrder,
  setSortingOrder,
  setSortColumn,
  setIsRemainderOpen,
  setIsDeleteModalOpen,
  pageLimit,
  setPageLimit,
  contractCount,
  setContractCount,
  showAdvanceSearchData,
  setShowAdvanceSearchData,
  getContracts,
  setIsRenewalAbsent,
  showDrawer,
  setPageLoading,
  setAdvanceSearchText,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: contractTableColumns, data: contractsData });
  const api = useAxios();
  const { setEditFormData, setIsEditing } = useContext(FormContext);
  const [currentRow, setCurrentRow] = useState(0);
  const { setActiveLink } = useContext(AppContext);
  const { user } = useContext(AuthContext);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isMultiSelect, setIsMultiSelect] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [repos, setRepos] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const getRepos = async () => {
    const res = await resolve(
      api.get(`contract/get_all_repos/`).then((res) => res.data)
    );
    if (res.data) {
      const filterData = [];
      if (res.data && res.data.length > 0) {
        res.data.forEach((result) => {
          const contractObj = {
            repo_name: result.repo_name,
            id: result.id,
          };
          filterData.push(contractObj);
        });
      }
      setRepos([...filterData]);
    }
  };

  const deleteContracts = async () => {
    setPageLoading(true);
    const res = await resolve(
      api
        .post(
          `contract/delete_multiple_contracts/`,
          { id_list: selectedIds },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then((res) => res.data)
    );
    if (res.data) {
      if (res.data.success) {
        getContracts();
      }
    }
    setDeleteModalOpen(false);
    setPageLoading(false);
  };
  useEffect(() => {
    getRepos();
  }, []);
  return (
    <>
      {contractsData.length > 0 && (
        <div className='mt-3 flex justify-between'>
          <div className='flex'>
            <div>
              {contractCount > 5 && (
                <>
                  <span>Show</span>
                  <select
                    className='border-2 border-primary outline-none mx-1 rounded-md cursor-pointer'
                    onChange={(e) => setPageLimit(e.target.value)}
                  >
                    <option selected>5</option>
                    {/* {contractCount > 5 && <option>10</option>}
                  {contractCount > 10 && <option>15</option>}
                  {contractCount > 15 && <option>25</option>} */}
                    {contractCount >= 10 && <option>10</option>}
                    {contractCount >= 15 && <option>15</option>}
                    {contractCount >= 25 && <option>25</option>}
                  </select>
                  <span>Results</span>
                </>
              )}
            </div>
            {showAdvanceSearchData && (
              <button
                className='ml-3 w-14 bg-primary rounded-lg text-white text-sm space-x-1'
                onClick={() => {
                  setAdvanceSearchText('');
                  setShowAdvanceSearchData(false);
                  getContracts();
                }}
              >
                <b>X</b> Clear
              </button>
            )}
          </div>
          <div className='flex text-primary  border-t-2 border-b-2 border-primary rounded-lg'>
            <button
              className={`pl-5 pr-3 border-r-2 border-l-2 border-primary font-semibold rounded-tl-lg rounded-bl-lg ${
                !prevPage && 'bg-inputBackground'
              }`}
              disabled={!prevPage}
              onClick={(e) => {
                setCurrentRow(0);
                if (e.detail == 1) {
                  if (prevPage) setCurrentPage(currentPage - 1);
                }
              }}
            >
              Prev
            </button>
            <span className='px-2 font-semibold'>{currentPage}</span>
            <button
              className={`pr-5 pl-3 border-l-2 border-r-2 border-primary font-semibold rounded-tr-lg rounded-br-lg ${
                !nextPage && 'bg-inputBackground'
              }`}
              disabled={!nextPage}
              onClick={(e) => {
                setCurrentRow(0);
                if (e.detail == 1) {
                  if (nextPage) {
                    setCurrentPage(currentPage + 1);
                  }
                }
              }}
            >
              Next
            </button>
          </div>
        </div>
      )}

      <div className='mt-2 flex table-container mb-5'>
        {isDrawerOpen && showDrawer && (
          <div
            className='flex flex-col justify-around items-center'
            style={{
              border: '1px solid #400835',
              borderRight: 'none',
              borderTopLeftRadius: '10px',
              borderBottomLeftRadius: '10px',
              height: 'fit-content',
              padding: '0 3px',
            }}
          >
            <img
              src={Selection}
              style={{
                width: '20px',
                height: '20px !important',
                cursor: 'pointer',
                margin: '2px 0 5px 2px',
              }}
              onClick={() => {
                setSelectedIds([]);
                setIsMultiSelect(!isMultiSelect);
              }}
              data-tip='Select Contracts'
            />
            <ReactTooltip
              backgroundColor='#400835'
              textColor='#ffffff'
              className='max-w-lg break-words'
            />
            {selectedIds.length > 0 && (
              <>
                <img
                  src={moveIcon}
                  style={{
                    width: '20px',
                    height: '20px !important',
                    cursor: 'pointer',
                    marginBottom: '10px',
                  }}
                  data-tip='Move Contracts'
                  onClick={() => setIsModalOpen(true)}
                />
                <ReactTooltip
                  backgroundColor='#400835'
                  textColor='#ffffff'
                  className='max-w-lg break-words'
                />
                <img
                  src={TrashIcon}
                  style={{
                    width: '20px',
                    height: '20px !important',
                    cursor: 'pointer',
                    marginBottom: '10px',
                  }}
                  data-tip='Delete Contracts'
                  onClick={() => setDeleteModalOpen(true)}
                />
                <ReactTooltip
                  backgroundColor='#400835'
                  textColor='#ffffff'
                  className='max-w-lg break-words'
                />
              </>
            )}
            <span
              className='text-sm font-bold  bg-primary text-white items-center justify-center'
              onClick={() => {
                setIsMultiSelect(false);
                setIsDrawerOpen(false);
                setSelectedIds([]);
              }}
              style={{
                display: 'flex',
                cursor: 'pointer',
                border: '1px solid #400835',
                borderRadius: '100%',
                height: 16,
                width: 16,
                marginBottom: '5px',
              }}
              data-tip='Close'
            >
              {'X'}
            </span>
            <ReactTooltip
              backgroundColor='#400835'
              textColor='#ffffff'
              className='max-w-lg break-words'
            />
          </div>
        )}
        {!isDrawerOpen && showDrawer && (
          <>
            <span
              className='text-lg font-semibold bg-primary text-white'
              onClick={() => setIsDrawerOpen(true)}
              style={{
                borderTopLeftRadius: '5px',
                borderBottomLeftRadius: '5px',
                height: 30,
                display: 'grid',
                placeItems: 'center',
                cursor: 'pointer',
              }}
              data-tip='Open'
            >
              {'<'}
            </span>
            <ReactTooltip
              backgroundColor='#400835'
              textColor='#ffffff'
              className='max-w-lg break-words'
            />
          </>
        )}

        <div
          className='shadow overflow-hidden border-2 border-primary  sm:rounded-lg max-h-96 overflow-y-auto'
          style={{ width: '100%', borderTopLeftRadius: 0 }}
        >
          <table {...getTableProps()} className='w-full '>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props

                    <th
                      scope='col'
                      className='text-left px-2 pt-2'
                      {...column
                        .getHeaderProps
                        // column.getSortByToggleProps()
                        ()}
                    >
                      <div className='flex items-center'>
                        <span>{column.render('Header')}</span>
                        {/* Add a sort direction indicator */}
                        {sortingOrder === 'desc' ? (
                          <span
                            onClick={(e) => {
                              setSortingOrder('asc');
                              setSortColumn(column.id);
                            }}
                            style={{ cursor: 'pointer', marginTop: 2 }}
                            className='ml-1'
                          >
                            {/* &#11165; */}
                            <img src={DownArrow} />
                          </span>
                        ) : (
                          <span
                            onClick={(e) => {
                              setSortingOrder('desc');
                              setSortColumn(`-${column.id}`);
                            }}
                            style={{
                              cursor: 'pointer',
                              transform: 'rotate(180deg)',
                            }}
                            className='ml-1'
                          >
                            <img src={DownArrow} />
                          </span>
                        )}
                      </div>
                    </th>
                  ))}
                  <th scope='col' className='text-left px-6 pt-2'>
                    Action
                  </th>
                  <th scope='col' className='text-left px-6 pt-2'>
                    Notifications
                  </th>
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {contractsData.length < 1 && (
                <NoDataComponent colLength={contractTableColumns.length} />
              )}
              {contractsData.length > 0 &&
                rows.map((row, i) => {
                  // new
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      onClick={() => {
                        setCurrentRow(i);
                        setSelectedContract({
                          ...selectedContract,
                          doc_name: row.original.doc_name,
                          file_url: row.original.file_url,
                          converted_file_url: row.original.converted_file_url,
                          id: row.original.id,
                        });
                      }}
                      className={`cursor-pointer ${
                        currentRow === i && 'bg-[#F6C107]'
                      }`}
                      style={{ position: 'relative' }}
                    >
                      {isMultiSelect && user.user_type === 'admin' ? (
                        <input
                          type='checkbox'
                          style={{
                            position: 'absolute',
                            top: '30%',
                            left: '2px',
                            cursor: 'pointer',
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (e.target.checked) {
                              setSelectedIds([...selectedIds, row.original.id]);
                            } else {
                              let filterData = selectedIds.filter(
                                (item) => item !== row.original.id
                              );
                              setSelectedIds([...filterData]);
                            }
                          }}
                          checked={selectedIds.includes(row.original.id)}
                        />
                      ) : isMultiSelect &&
                        row.original.uploaded_by === user.user_id ? (
                        <input
                          type='checkbox'
                          style={{
                            position: 'absolute',
                            top: '30%',
                            left: '2px',
                            cursor: 'pointer',
                          }}
                          onClick={(e) => {
                            if (e.target.checked) {
                              e.stopPropagation();
                              setSelectedIds([...selectedIds, row.original.id]);
                            } else {
                              let filterData = selectedIds.filter(
                                (item) => item !== row.original.id
                              );
                              setSelectedIds([...filterData]);
                            }
                          }}
                          checked={selectedIds.includes(row.original.id)}
                        />
                      ) : null}
                      {row.cells.map((cell) => {
                        return (
                          <>
                            <td
                              {...cell.getCellProps()}
                              className={`px-2 py-2 font-medium text-sm contracts-col-data ${
                                isMultiSelect ? 'ml-3' : ''
                              } ${
                                cell.value === 'In-Active' && 'text-red-500'
                              }`}
                              style={
                                cell.column.Header === 'Contract Name'
                                  ? {
                                      width: 220,
                                      overflow: 'hidden',
                                      display: 'inline-block',
                                      textOverflow: 'ellipsis',
                                    }
                                  : null
                              }
                            >
                              <span
                                data-tip={
                                  cell.column.Header === 'Contract Name' &&
                                  cell.value.length > 20
                                    ? cell.value
                                    : null
                                }
                              >
                                {cell.render('Cell')}
                              </span>
                              {cell.column.Header === 'Contract Name' && (
                                <ReactTooltip
                                  backgroundColor='#400835'
                                  textColor='#ffffff'
                                  className='max-w-lg break-words'
                                />
                              )}
                            </td>
                          </>
                        );
                      })}
                      <td className='px-4 py-2'>
                        {/* <button
                              className='bg-secondary text-white font-semibold p-1 pl-6 pr-6 rounded-lg'
                              onClick={() => {
                                setEditFormData(row.original);
                                setIsEditing(true);
                                setActiveLink('ContractForm');
                              }}
                            >
                              Edit
                            </button> */}
                        <span
                          className={`bg-secondary rounded-lg ${
                            user.user_type === 'admin' ||
                            row.original.uploaded_by === user.user_id
                              ? 'flex justify-between'
                              : 'flex justify-center'
                          } px-1 py-0.5`}
                        >
                          <img
                            src={EditIcon}
                            width={25}
                            onClick={() => {
                              setEditFormData(row.original);
                              setIsEditing(true);
                              setActiveLink('ContractForm');
                            }}
                          />
                          {user.user_type === 'admin' ||
                          row.original.uploaded_by === user.user_id ? (
                            <img
                              src={DeleteIcon}
                              width={25}
                              onClick={() => setIsDeleteModalOpen(true)}
                            />
                          ) : null}
                        </span>
                      </td>
                      <td className='py-2'>
                        <button
                          className='bg-secondary text-white  p-1 px-4 text-sm font-bold rounded-lg'
                          onClick={() => {
                            const renewalPresent =
                              row.original &&
                              row.original.renewal_date === 'Not Added';
                            setEditFormData(row.original);
                            setIsRemainderOpen(true);
                            setIsRenewalAbsent(renewalPresent);
                          }}
                        >
                          {(row.original && row.original.reminder_days) ||
                          (row.original && row.original.reminder_date)
                            ? ' Edit Reminder'
                            : ' Add Reminder'}
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
      <MoveRepoModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        repos={repos}
        selectedIds={selectedIds}
        setPageLoading={setPageLoading}
      />
      <DeleteModal
        title='Delete Contracts'
        bodyText='This action cannot be undone. Are you sure you want to continue?'
        isDeleteModalOpen={deleteModalOpen}
        setIsDeleteModalOpen={setDeleteModalOpen}
        deleteContracts={deleteContracts}
      />
    </>
  );
};

export default ContractsTable;
