import React, { useContext } from 'react';
import AppContext from '../context/AppContext';
import Group from '../assets/icons/group.png';

const AddButton = ({ text, currentForm }) => {
  const { setActiveLink } = useContext(AppContext);
  const onBtnClick = async () => {
    switch (currentForm) {
      case 'Companies':
        return setActiveLink('CompanyForm');
      case 'Admins':
        return setActiveLink('AdminForm');
      case 'Users':
        return setActiveLink('UserForm');
      case 'Reviewers':
        return setActiveLink('ReviewerForm');
      case 'Contracts':
        return setActiveLink('ContractForm');
    }
  };
  return (
    <button
      className='bg-primary text-white p-1 px-3 ml-2 rounded-lg'
      onClick={() => onBtnClick()}
    >
      <span className='flex'>
        <img src={Group} width={20} className='rounded-md' />
        <span className='font-semibold ml-2'>{text}</span>
      </span>
    </button>
  );
};

export default AddButton;
