import React, { useState, useEffect, useContext } from 'react';
import dayjs from 'dayjs';
import NotificationsTable from './NotificationsTable';
import { notificationTableColumns } from '../../constants/constants';
import useAxios from '../../hooks/useAxios';
import { resolve } from '../../api/resolve';
import NotificationModal from '../modals/NotificationModal';
import AppContext from '../../context/AppContext';
import LoadingScreen from '../loader/LoadingScreen';

const Notifications = () => {
  const api = useAxios();
  const { getNotificationsCount } = useContext(AppContext);
  const [errMsg, setErrMsg] = useState('');
  const [nextPage, setIsNextPage] = useState(null);
  const [prevPage, setIsPrevPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortingOrder, setSortingOrder] = useState('desc');
  const [sortColumn, setSortColumn] = useState('expiry_at');
  const [notifications, setNotifications] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState([]);
  const getNotifications = async () => {
    const res = await resolve(
      api
        .get(
          `notification/upcoming_notifications/?ordering=${sortColumn}&page=${currentPage}`
        )
        .then((res) => res.data)
    );

    if (res.data) {
      const { results, next, previous } = res.data;
      let filterData = [];
      if (results.length > 0) {
        results.forEach((result) => {
          const contractObj = {
            id: result.id,
            title: result.msg,
            date: dayjs(result.for_date).format('DD MMMM YYYY'),
            read: result.is_seen ? 'Seen' : 'Not Seen',
            contract_details: result.contract_details,
            type: result.type,
          };
          filterData.push(contractObj);
        });
      } else {
        setErrMsg('No notifications found');
      }
      filterData.forEach((item) => {
        if (item.contract_details) {
          item.contract_details.replaceAll('\\', '');
          const parsedObj = JSON.parse(item.contract_details);
          item.contract_details = parsedObj;
        }
      });
      setNotifications([...filterData]);
      setIsNextPage(next);
      setIsPrevPage(previous);
    }
    setPageLoading(false);
  };

  const updateNotificationSeen = async (id) => {
    const res = await resolve(
      api.get(`notification/update_is_seen/${id}`).then((res) => res.data)
    );
    if (res.data) {
      getNotificationsCount();
      getNotifications();
    }
  };
  const updateNotificationCleared = async (id) => {
    const res = await resolve(
      api.get(`notification/update_is_cleared/${id}`).then((res) => res.data)
    );
    if (res.data) {
      getNotificationsCount();
      getNotifications();
    }
  };

  const clearAllNotifications = async () => {
    setPageLoading(true);
    const res = await resolve(
      api.get(`notification/clear_all/`).then((res) => res.data)
    );
    if (res.data) {
      getNotificationsCount();
      getNotifications();
    }
    setPageLoading(false);
  };
  useEffect(() => {
    getNotificationsCount();
    getNotifications();
  }, [currentPage, sortColumn]);
  return (
    <>
      {pageLoading ? (
        <LoadingScreen />
      ) : (
        <div className='text-primary'>
          <h1 className='text-4xl font-bold'>Upcoming Notifications</h1>
          {notifications.length < 1 && (
            <p className='text-red-500 text-sm font-medium my-5'>{errMsg}</p>
          )}
          <NotificationsTable
            contractsData={notifications}
            contractTableColumns={notificationTableColumns}
            prevPage={prevPage}
            nextPage={nextPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            sortingOrder={sortingOrder}
            setSortingOrder={setSortingOrder}
            setSortColumn={setSortColumn}
            setIsOpen={setIsOpen}
            updateNotificationSeen={updateNotificationSeen}
            updateNotificationCleared={updateNotificationCleared}
            setSelectedNotification={setSelectedNotification}
            clearAllNotifications={clearAllNotifications}
          />
          <NotificationModal
            modalIsOpen={modalIsOpen}
            setIsOpen={setIsOpen}
            notifications={notifications}
            selectedNotification={selectedNotification}
          />
        </div>
      )}
    </>
  );
};

export default Notifications;
