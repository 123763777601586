import React, { useState, useContext } from 'react';
import { useTable } from 'react-table';
import DownArrow from '../../assets/icons/down-arrow.svg';
import FormContext from '../../context/FormContext';
import AppContext from '../../context/AppContext';

export const NoDataComponent = ({ colLength }) => {
  const [rows] = useState(Array(4).fill(''));
  const [cols] = useState(Array(colLength).fill(''));
  return (
    <>
      {rows.map(() => (
        <tr>
          {cols.map(() => (
            <td className='px-5 py-2 font-medium text-sm text-center'>--</td>
          ))}
        </tr>
      ))}
    </>
  );
};

const NotificationsTable = ({
  contractsData,
  contractTableColumns,
  prevPage,
  nextPage,
  currentPage,
  setCurrentPage,
  setSelectedNotification,
  sortingOrder,
  setSortingOrder,
  setSortColumn,
  setIsOpen,
  updateNotificationSeen,
  updateNotificationCleared,
  clearAllNotifications,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: contractTableColumns, data: contractsData });
  return (
    <>
      <div className='mt-5 flex flex-col table-container'>
        <div>
          <div>
            <div className='shadow overflow-hidden border-2 border-primary  sm:rounded-lg max-h-96'>
              <table {...getTableProps()} className='w-full '>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props

                        <th
                          scope='col'
                          className='text-left px-2 pt-2'
                          {...column
                            .getHeaderProps
                            // column.getSortByToggleProps()
                            ()}
                        >
                          <div className='flex items-center  justify-center'>
                            <span>{column.render('Header')}</span>
                            {column.id === 'date' && (
                              <>
                                {sortingOrder === 'desc' ? (
                                  <span
                                    onClick={(e) => {
                                      setSortingOrder('asc');
                                      setSortColumn(column.id);
                                    }}
                                    style={{ cursor: 'pointer', marginTop: 2 }}
                                    className='ml-1'
                                  >
                                    {/* &#11165; */}
                                    <img src={DownArrow} />
                                  </span>
                                ) : (
                                  <span
                                    onClick={(e) => {
                                      setSortingOrder('desc');
                                      setSortColumn(`-${column.id}`);
                                    }}
                                    style={{
                                      cursor: 'pointer',
                                      transform: 'rotate(180deg)',
                                    }}
                                    className='ml-1'
                                  >
                                    <img src={DownArrow} />
                                  </span>
                                )}
                              </>
                            )}
                          </div>
                        </th>
                      ))}
                      <th scope='col' className='text-center px-6 pt-2'>
                        Action
                      </th>
                      {/* <th scope='col' className='text-left px-6 pt-2'>
                        Notifications
                      </th> */}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {contractsData.length < 1 && (
                    <NoDataComponent colLength={contractTableColumns.length} />
                  )}
                  {contractsData.length > 0 &&
                    rows.map((row, i) => {
                      // new
                      prepareRow(row);
                      return (
                        <tr
                          {...row.getRowProps()}
                          className={`text-center `}
                          onClick={() => console.log(row.original)}
                        >
                          {row.cells.map((cell) => {
                            return (
                              <>
                                <td
                                  {...cell.getCellProps()}
                                  className={`px-2 py-2 font-medium text-sm contracts-col-data  ${
                                    cell.value === 'Not Seen' && 'text-red-500'
                                  }`}
                                >
                                  {cell.value === 'Seen' ? (
                                    <span className='text-lg'>&#10003;</span>
                                  ) : cell.value === 'Not Seen' ? (
                                    <span className='text-lg'>&#10008;</span>
                                  ) : (
                                    cell.render('Cell')
                                  )}
                                </td>
                              </>
                            );
                          })}
                          <td className='px-4 py-2 flex justify-center'>
                            {row.original.contract_details &&
                              row.original.type !==
                                'notify_indexing_failed' && (
                                <button
                                  className='bg-secondary text-white font-semibold p-1 pl-4 pr-4 rounded-lg'
                                  onClick={() => {
                                    updateNotificationSeen(row.original.id);
                                    let contractObj =
                                      row.original.contract_details;
                                    contractObj[0].type = row.original.type;
                                    setSelectedNotification(contractObj);
                                    setIsOpen(true);
                                  }}
                                >
                                  View
                                </button>
                              )}
                            <button
                              className='ml-3 bg-secondary text-white font-semibold p-1 pl-4 pr-4 rounded-lg'
                              onClick={() =>
                                updateNotificationCleared(row.original.id)
                              }
                            >
                              Clear
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {contractsData.length > 0 && (
        <div className='mt-3 flex justify-between'>
          <div>
            <button
              className='bg-secondary text-white font-semibold p-1 pl-4 pr-4 rounded-md'
              onClick={() => clearAllNotifications()}
            >
              Clear All
            </button>
          </div>
          <div className='flex text-primary  border-t-2 border-b-2 border-primary rounded-lg'>
            <button
              className={`pl-5 pr-3 border-r-2 border-l-2 border-primary font-semibold rounded-tl-lg rounded-bl-lg ${
                !prevPage && 'bg-inputBackground'
              }`}
              disabled={!prevPage}
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              Prev
            </button>
            <span className='px-2 pt-1 font-semibold'>{currentPage}</span>
            <button
              className={`pr-5 pl-3 border-l-2 border-r-2 border-primary font-semibold rounded-tr-lg rounded-br-lg ${
                !nextPage && 'bg-inputBackground'
              }`}
              disabled={!nextPage}
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              Next
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default NotificationsTable;
