import React, { useEffect, useState, useContext } from 'react';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import AppContext from '../../../context/AppContext';
import { dropDownStyles } from '../../../constants/styles';
import useAxios from '../../../hooks/useAxios';
import { resolve } from '../../../api/resolve';
import LoadingScreen from '../../loader/LoadingScreen';
import AppModal from '../../modals/Modal';
import { formatSelectedClause } from '../../../utils/utils';
const TrainingResults = () => {
  const api = useAxios();
  const { modelID } = useContext(AppContext);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imgUrl, setImgUrl] = useState('');
  const [jsonData, setJsonData] = useState('');
  const [compareImgUrl, setCompareImgUrl] = useState('');
  const [compareJsonData, setCompareJsonData] = useState('');
  const [selectedVersion, setSelectedVersion] = useState();
  const [currentVersion, setCurrentVersion] = useState(null);
  const [comparedResult, setComparedResult] = useState(null);
  const [isModalOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [bodyText, setBodyText] = useState('');
  const [values, setValues] = useState([]);
  const [comparedValues, setComparedValues] = useState([]);
  const [accuracyValues, setAccuracyValues] = useState({
    single: '',
    compared: '',
  });
  const getVersions = async () => {
    setLoading(true);
    const res = await resolve(
      api.get(`/reviewer/get_all_api_versions/`).then((res) => res.data)
    );
    if (res.data) {
      const values = [];
      res.data.forEach((item) =>
        values.push({
          value: `Version ${item.version.toFixed(1)}`,
          label: `Version ${item.version.toFixed(1)}`,
        })
      );
      setOptions([...values]);
    }
    setLoading(false);
  };
  const getResults = async () => {
    setLoading(true);
    const res = await resolve(
      api
        .get(`/reviewer/view_training_results/${modelID}`)
        .then((res) => res.data)
    );
    if (res.data) {
      setCurrentVersion(`Version ${res.data.version.toFixed(1)}`);
      setImgUrl(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${res.data.confusion_matrix}`
      );
      const data = await resolve(
        api
          .get(
            `${process.env.REACT_APP_BACKEND_BASE_URL}${res.data.classification_report}`
          )
          .then((res) => res.data)
      );
      const jsonData = data.data;
      let temp_arr = [];
      Object.keys(jsonData).forEach((key) => {
        if (key !== 'accuracy') temp_arr.push(key);
      });
      let values_arr = [];
      Object.values(jsonData)
        .filter((item) => Object.keys(item).length > 0)
        .forEach((obj, idx) => {
          const obj_arr = Object.values(obj);
          obj_arr.unshift(temp_arr[idx]);
          values_arr.push(obj_arr);
        });
      setValues([...values_arr]);
      setAccuracyValues({ ...accuracyValues, single: jsonData.accuracy });
      setJsonData(
        `${jsonData}`.replace('              precision', '   Serial  Precision')
      );
    }
    setLoading(false);
  };
  const getComparisionResults = async () => {
    setLoading(true);
    const res = await resolve(
      api
        .get(
          `/reviewer/compare_training_results/${selectedVersion.value
            .toLowerCase()
            .replace('version', '')
            .trim()}`
        )
        .then((res) => res.data)
    );
    if (res.data) {
      setComparedResult(res.data);
      setCompareImgUrl(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${res.data.confusion_matrix}`
      );
      const data = await resolve(
        api
          .get(
            `${process.env.REACT_APP_BACKEND_BASE_URL}${res.data.classification_report}`
          )
          .then((res) => res.data)
      );
      const jsonData = data.data;
      let temp_arr = [];
      Object.keys(jsonData).forEach((key) => {
        if (key !== 'accuracy') temp_arr.push(key);
      });
      let values_arr = [];
      Object.values(jsonData)
        .filter((item) => Object.keys(item).length > 0)
        .forEach((obj, idx) => {
          const obj_arr = Object.values(obj);
          obj_arr.unshift(temp_arr[idx]);
          values_arr.push(obj_arr);
        });
      setComparedValues([...values_arr]);
      setAccuracyValues({ ...accuracyValues, compared: jsonData.accuracy });
      setCompareJsonData(
        `${jsonData}`.replace('              precision', '   Serial  Precision')
      );
    }
    setLoading(false);
  };
  const deploySelectedVersion = async (version) => {
    setLoading(true);
    const res = await resolve(
      api.get(`/reviewer/deploy_model/${version}`).then((res) => res.data)
    );
    if (res.data) {
      setIsOpen(true);
      setTitle('Model Deployed');
      setBodyText(`Model Deployed with version : ${version}`);
    } else {
      setIsOpen(true);
      setTitle('Deployment Failed');
      setBodyText(`Please try again later`);
    }
    setLoading(false);
  };

  useEffect(() => {
    getVersions();
    if (modelID) {
      getResults();
    }
  }, [modelID]);

  useEffect(() => {
    if (selectedVersion) {
      getComparisionResults();
    }
  }, [selectedVersion]);
  if (loading) {
    return <LoadingScreen />;
  }
  return (
    <div className='text-primary'>
      <h1 className='text-4xl font-bold'>Training Results</h1>
      <div className='mt-3'>
        <Select
          options={options}
          placeholder='Compare with'
          styles={dropDownStyles}
          isSearchable={true}
          className='w-3/12 text-primary'
          value={selectedVersion}
          onChange={(version) => {
            setSelectedVersion(version);
          }}
        />
      </div>
      {comparedResult ? (
        <div className='my-3'>
          <div className='flex  justify-between items-center'>
            <div className='w-1/2'>
              <h2 className='text-center text-primary font-semibold text-lg'>
                Confusion Matrix{' '}
                <span>{`(${currentVersion
                  .toLowerCase()
                  .trim()
                  .replaceAll(' ', '')
                  .replace('version', 'v')})`}</span>
              </h2>
              <div className='border-2 border-primary rounded-md  w-full mt-1 cursor-move p-0.5'>
                <TransformWrapper>
                  {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <div className='flex'>
                      <TransformComponent>
                        <img
                          src={imgUrl}
                          alt='test'
                          style={{ height: 400, width: 500 }}
                        />
                      </TransformComponent>
                      <div className='flex flex-col ml-auto w-10'>
                        <>
                          <button
                            onClick={() => zoomIn()}
                            className='bg-primary px-1 py-0.5 m-2 text-white font-bold'
                            data-tip='Zoom In'
                          >
                            +
                          </button>
                          <ReactTooltip
                            backgroundColor='#400835'
                            textColor='#ffffff'
                            className='max-w-lg break-words'
                          />
                        </>
                        <>
                          <button
                            onClick={() => zoomOut()}
                            className='bg-primary px-1 py-0.5 m-2 text-white font-bold'
                            data-tip='Zoom Out'
                          >
                            -
                          </button>
                          <ReactTooltip
                            backgroundColor='#400835'
                            textColor='#ffffff'
                            className='max-w-lg break-words'
                          />
                        </>
                      </div>
                    </div>
                  )}
                </TransformWrapper>
              </div>
            </div>
            <div className='w-1/2 ml-3'>
              <h2 className='text-center text-primary font-semibold text-lg'>
                Confusion Matrix{' '}
                <span>{`(${selectedVersion.value
                  .toLowerCase()
                  .trim()
                  .replaceAll(' ', '')
                  .replace('version', 'v')})`}</span>
              </h2>
              <div className='border-2 border-primary rounded-md  w-full mt-1 cursor-move p-0.5'>
                <TransformWrapper>
                  {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <div className='flex'>
                      <TransformComponent>
                        <img
                          src={compareImgUrl}
                          alt='test'
                          style={{ height: 400, width: 500 }}
                        />
                      </TransformComponent>
                      <div className='flex flex-col ml-auto w-10'>
                        <>
                          <button
                            onClick={() => zoomIn()}
                            className='bg-primary px-1 py-0.5 m-2 text-white font-bold'
                            data-tip='Zoom In'
                          >
                            +
                          </button>
                          <ReactTooltip
                            backgroundColor='#400835'
                            textColor='#ffffff'
                            className='max-w-lg break-words'
                          />
                        </>
                        <>
                          <button
                            onClick={() => zoomOut()}
                            className='bg-primary px-1 py-0.5 m-2 text-white font-bold'
                            data-tip='Zoom Out'
                          >
                            -
                          </button>
                          <ReactTooltip
                            backgroundColor='#400835'
                            textColor='#ffffff'
                            className='max-w-lg break-words'
                          />
                        </>
                      </div>
                    </div>
                  )}
                </TransformWrapper>
              </div>
            </div>
          </div>
          <div className='my-2 text-center w-fit mx-auto flex items-center'>
            {' '}
            <button
              className='bg-primary ml-5 px-3 py-1 text-white font-semibold rounded-md'
              onClick={() =>
                deploySelectedVersion(
                  currentVersion.toLowerCase().replace('version', '').trim()
                )
              }
            >
              Deploy{' '}
              {currentVersion
                .toLowerCase()
                .trim()
                .replaceAll(' ', '')
                .replace('version', 'v')}
            </button>
            <button
              className='bg-primary ml-5 px-3 py-1 text-white font-semibold rounded-md'
              onClick={() =>
                deploySelectedVersion(
                  selectedVersion.value
                    .toLowerCase()
                    .replace('version', '')
                    .trim()
                )
              }
            >
              Deploy{' '}
              {selectedVersion.value
                .toLowerCase()
                .trim()
                .replaceAll(' ', '')
                .replace('version', 'v')}
            </button>
          </div>
          {comparedResult && (
            <>
              <hr />
              <div className='flex  justify-between items-center'>
                <div className='w-1/2 ml-3'>
                  <h2 className='text-center text-primary font-semibold text-lg'>
                    Classification Report{' '}
                    <span>{`(${currentVersion
                      .toLowerCase()
                      .trim()
                      .replaceAll(' ', '')
                      .replace('version', 'v')})`}</span>
                  </h2>
                  {/* <textarea
                    value={jsonData}
                    rows={10}
                    className='border-2 border-primary rounded-md text-center px-10 block w-full text-lg mt-1'
                    style={{ height: 400 }}
                    disabled
                  ></textarea> */}
                  <div
                    className='overflow-x-auto relative rounded-md p-0.5'
                    style={{ height: 400, overflow: 'auto' }}
                  >
                    <table
                      className='w-full text-sm text-center '
                      style={{
                        height: '100%',
                        overflow: 'auto',
                        borderCollapse: 'collapse',
                        border: '1px solid #400835',
                      }}
                    >
                      <thead className='text-sm'>
                        <tr>
                          <th scope='col' className='border-r-2 border-primary'>
                            Title
                          </th>
                          <th scope='col' className='border-r-2 border-primary'>
                            Precision
                          </th>
                          <th scope='col' className='border-r-2 border-primary'>
                            Recall
                          </th>
                          <th scope='col' className='border-r-2 border-primary'>
                            F1-Score
                          </th>
                          <th scope='col' className='border-r-2 border-primary'>
                            Support
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.map((obj) => (
                          <tr>
                            {obj.map((val) => (
                              <td
                                style={{
                                  border: '1px solid #400835',
                                  textAlign: 'center',
                                }}
                              >
                                {typeof val == 'number'
                                  ? val.toFixed(2)
                                  : formatSelectedClause(
                                      val.replaceAll('_', '')
                                    )}
                              </td>
                            ))}
                          </tr>
                        ))}
                        <tr>
                          <td
                            style={{
                              border: '1px solid #400835',
                              textAlign: 'center',
                            }}
                          >
                            Accuracy
                          </td>
                          <td
                            style={{
                              border: '1px solid #400835',
                              textAlign: 'center',
                            }}
                          >
                            {Number(accuracyValues.single).toFixed(2)}
                          </td>
                          <td
                            style={{
                              border: '1px solid #400835',
                              textAlign: 'center',
                            }}
                          >
                            -
                          </td>
                          <td
                            style={{
                              border: '1px solid #400835',
                              textAlign: 'center',
                            }}
                          >
                            -
                          </td>
                          <td>-</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className='w-1/2 ml-3'>
                  <h2 className='text-center text-primary font-semibold text-lg'>
                    Classification Report{' '}
                    <span>{`(${selectedVersion.value
                      .toLowerCase()
                      .trim()
                      .replaceAll(' ', '')
                      .replace('version', 'v')})`}</span>
                  </h2>
                  {/* <textarea
                    value={compareJsonData}
                    rows={10}
                    className='border-2 border-primary rounded-md text-center px-10 block w-full text-lg mt-1'
                    style={{ height: 400 }}
                    disabled
                  ></textarea> */}
                  <div
                    className='overflow-x-auto relative rounded-md p-0.5'
                    style={{ height: 400, overflow: 'auto' }}
                  >
                    <table
                      className='w-full text-sm text-center '
                      style={{
                        height: '100%',
                        overflow: 'auto',
                        borderCollapse: 'collapse',
                        border: '1px solid #400835',
                      }}
                    >
                      <thead className='text-sm'>
                        <tr>
                          <th scope='col' className='border-r-2 border-primary'>
                            Title
                          </th>
                          <th scope='col' className='border-r-2 border-primary'>
                            Precision
                          </th>
                          <th scope='col' className='border-r-2 border-primary'>
                            Recall
                          </th>
                          <th scope='col' className='border-r-2 border-primary'>
                            F1-Score
                          </th>
                          <th scope='col' className='border-r-2 border-primary'>
                            Support
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {comparedValues.map((obj) => (
                          <tr>
                            {obj.map((val) => (
                              <td
                                style={{
                                  border: '1px solid #400835',
                                  textAlign: 'center',
                                }}
                              >
                                {typeof val == 'number'
                                  ? val.toFixed(2)
                                  : formatSelectedClause(
                                      val.replaceAll('_', '')
                                    )}
                              </td>
                            ))}
                          </tr>
                        ))}
                        <tr>
                          <td
                            style={{
                              border: '1px solid #400835',
                              textAlign: 'center',
                            }}
                          >
                            Accuracy
                          </td>
                          <td
                            style={{
                              border: '1px solid #400835',
                              textAlign: 'center',
                            }}
                          >
                            {Number(accuracyValues.compared).toFixed(2)}
                          </td>
                          <td
                            style={{
                              border: '1px solid #400835',
                              textAlign: 'center',
                            }}
                          >
                            -
                          </td>
                          <td
                            style={{
                              border: '1px solid #400835',
                              textAlign: 'center',
                            }}
                          >
                            -
                          </td>
                          <td>-</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className='my-3'>
          <div className='flex  justify-between items-center'>
            <div className='w-1/2'>
              <h2 className='text-center text-primary font-semibold text-lg'>
                Confusion Matrix
              </h2>
              {imgUrl && (
                <div className='border-2 border-primary rounded-md  w-full mt-1 cursor-move p-0.5'>
                  <TransformWrapper>
                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                      <div className='flex'>
                        <TransformComponent>
                          <img
                            src={imgUrl}
                            alt='test'
                            style={{ height: 400, width: 500 }}
                          />
                        </TransformComponent>
                        <div className='flex flex-col ml-auto w-10'>
                          <>
                            <button
                              onClick={() => zoomIn()}
                              className='bg-primary px-1 py-0.5 m-2 text-white font-bold'
                              data-tip='Zoom In'
                            >
                              +
                            </button>
                            <ReactTooltip
                              backgroundColor='#400835'
                              textColor='#ffffff'
                              className='max-w-lg break-words'
                            />
                          </>
                          <>
                            <button
                              onClick={() => zoomOut()}
                              className='bg-primary px-1 py-0.5 m-2 text-white font-bold'
                              data-tip='Zoom Out'
                            >
                              -
                            </button>
                            <ReactTooltip
                              backgroundColor='#400835'
                              textColor='#ffffff'
                              className='max-w-lg break-words'
                            />
                          </>
                        </div>
                      </div>
                    )}
                  </TransformWrapper>
                </div>
              )}
            </div>
            <div className='w-1/2 ml-3'>
              <h2 className='text-center text-primary font-semibold text-lg'>
                Classification Report
              </h2>
              {/* <textarea
                value={jsonData}
                rows={10}
                className='border-2 border-primary rounded-md text-center px-10 block w-full text-lg mt-1'
                style={{ height: 400 }}
                disabled
              ></textarea> */}
              <div
                className='overflow-x-auto relative rounded-md p-0.5'
                style={{ height: 400, overflow: 'auto' }}
              >
                <table
                  className='w-full text-sm text-center '
                  style={{
                    height: '100%',
                    overflow: 'auto',
                    borderCollapse: 'collapse',
                    border: '1px solid #400835',
                  }}
                >
                  <thead className='text-sm'>
                    <tr>
                      <th scope='col' className='border-r-2 border-primary'>
                        Title
                      </th>
                      <th scope='col' className='border-r-2 border-primary'>
                        Precision
                      </th>
                      <th scope='col' className='border-r-2 border-primary'>
                        Recall
                      </th>
                      <th scope='col' className='border-r-2 border-primary'>
                        F1-Score
                      </th>
                      <th scope='col' className='border-r-2 border-primary'>
                        Support
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {values.map((obj) => (
                      <>
                        <tr>
                          {obj.map((val) => (
                            <td
                              style={{
                                border: '1px solid #400835',
                                textAlign: 'center',
                              }}
                            >
                              {typeof val == 'number'
                                ? val.toFixed(2)
                                : formatSelectedClause(val.replaceAll('_', ''))}
                            </td>
                          ))}
                        </tr>
                      </>
                    ))}
                    <tr>
                      <td
                        style={{
                          border: '1px solid #400835',
                          textAlign: 'center',
                        }}
                      >
                        Accuracy
                      </td>
                      <td
                        style={{
                          border: '1px solid #400835',
                          textAlign: 'center',
                        }}
                      >
                        {Number(accuracyValues.single).toFixed(2)}
                      </td>
                      <td
                        style={{
                          border: '1px solid #400835',
                          textAlign: 'center',
                        }}
                      >
                        -
                      </td>
                      <td
                        style={{
                          border: '1px solid #400835',
                          textAlign: 'center',
                        }}
                      >
                        -
                      </td>
                      <td>-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className='my-2 text-center w-fit mx-auto flex items-center'>
            {' '}
            <h2 className='border-2 border-primary  px-2 py-1 rounded-md'>
              {currentVersion}
            </h2>
            <button
              className='bg-primary ml-5 px-3 py-1 text-white font-semibold rounded-md'
              onClick={() =>
                deploySelectedVersion(
                  currentVersion.toLowerCase().replace('version', '').trim()
                )
              }
            >
              Deploy
            </button>
          </div>
        </div>
      )}
      <AppModal
        title={title}
        bodyText={bodyText}
        modalIsOpen={isModalOpen}
        setIsOpen={setIsOpen}
      />
    </div>
  );
};

export default TrainingResults;
