import React, { useState, useContext, useEffect } from 'react';
import 'chart.js/auto';
import { Pie } from 'react-chartjs-2';
import { resolve } from '../../api/resolve';
import useAxios from '../../hooks/useAxios';
import Search from '../../assets/icons/Search.svg';
import Select from 'react-select';
import { customStyles } from '../../constants/styles';
import AppContext from '../../context/AppContext';

const PieChart = () => {
  const api = useAxios();
  const { companiesList, getCompanies } = useContext(AppContext);
  const [chartData, setChartData] = useState({
    repo_count: 0,
    admin_count: 0,
    user_count: 0,
    contracts_count: 0,
  });
  const [dataset, setDataset] = useState({
    datasets: [
      {
        label: 'My First Dataset',
        data: [0, 0, 0, 0],
        backgroundColor: ['#400835', '#E289F2', '#000000', '#ACB9FF'],
        borderWidth: 0,
      },
    ],
  });
  const getPieChartData = async (companyId) => {
    const res = await resolve(
      api.get(`/dashboard/data_by_company/${companyId}`).then((res) => res.data)
    );
    if (res.data) {
      const { repo_count, admin_count, user_count, contracts_count } = res.data;
      setChartData({ repo_count, admin_count, user_count, contracts_count });
      setDataset({
        datasets: [
          {
            label: 'My First Dataset',
            data: [repo_count, admin_count, user_count, contracts_count],
            backgroundColor: ['#400835', '#E289F2', '#000000', '#ACB9FF'],
            borderWidth: 0,
          },
        ],
      });
    }
  };
  var chartpluginsset = [
    {
      afterDraw: (chart) => {
        let isDataAVaialable = false;
        if (chart['_metasets'] && chart['_metasets'].length > 0) {
          chart['_metasets'].forEach((arr) =>
            arr['_dataset'].data.forEach((item) => {
              if (item > 0) isDataAVaialable = true;
            })
          );
        }
        if (!isDataAVaialable) {
          // No data is present
          var ctx = chart.ctx;
          var width = chart.width;
          var height = chart.height;
          chart.clear();

          ctx.save();
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.font = '14px "Helvetica Neue", Helvetica, Arial, sans-serif';
          ctx.fillStyle = '#400835';
          ctx.fillText('No data to display', width / 2, height / 2);
          ctx.restore();
        }
      },
    },
  ];

  useEffect(() => {
    getCompanies();
    getPieChartData(0);
  }, []);
  return (
    <div className='border-2 border-primary doughnut-container p-5 mt-5'>
      <div className='flex justify-between'>
        <h1 className='font-bold text-xl text-primary'>Data By Company</h1>
        <div className='flex rounded-lg border-2 border-primary bg-inputBackground pl-1'>
          <Select
            options={companiesList}
            placeholder='Search By Company'
            styles={{
              ...customStyles,
              control: (provided, state) => {
                return {
                  ...provided,
                  outline: 'none',
                  width: 200,
                  boxShadow: 'none',
                  border: 'none',
                  borderRadius: '0',
                  background: 'transparent',
                  cursor: 'pointer',
                  '&:hover': {
                    border: 'none',
                    boxShadow: 'none',
                  },
                };
              },
            }}
            isSearchable={true}
            onChange={(company) => {
              getPieChartData(company.id);
            }}
            className='text-primary rounded-lg'
          />
          <div className='bg-primary p-2 grid place-items-center'>
            <img src={Search} style={{ width: '1rem' }} alt='Search-icon' />
          </div>
        </div>
      </div>
      <div className='flex items-center'>
        <div style={{ width: '150px' }}>
          <Pie data={dataset} plugins={chartpluginsset}></Pie>
        </div>
        <div className='ml-5 w-3/5'>
          <ul className='grid grid-cols-2'>
            <li className='flex items-center'>
              <div
                className='bg-primary mr-1'
                style={{ width: '15px', height: '15px', borderRadius: '100%' }}
              ></div>
              <span>{chartData.repo_count} Repositories</span>
            </li>
            <li className='flex items-center'>
              <div
                className='bg-[#E289F2] mr-1'
                style={{ width: '15px', height: '15px', borderRadius: '100%' }}
              ></div>
              <span>{chartData.admin_count} Admins</span>
            </li>
            <li className='flex items-center  mt-4'>
              <div
                className='bg-[#ACB9FF] mr-1'
                style={{ width: '15px', height: '15px', borderRadius: '100%' }}
              ></div>
              <span>{chartData.contracts_count} Contracts</span>
            </li>
            <li className='flex items-center mt-4'>
              <div
                className='bg-[#000000] mr-1'
                style={{ width: '15px', height: '15px', borderRadius: '100%' }}
              ></div>
              <span>{chartData.user_count} Users</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PieChart;
