import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import { AppProvider } from '../context/AppContext';
import { AuthProvider } from '../context/AuthContext';
import { FormProvider } from '../context/FormContext';
import Login from '../components/Login/Login';
import ForgotPassword from '../components/forgot-password/ForgotPassword';
import ResetPassword from '../components/reset-password/ResetPassword';
import Dashboard from '../components/dashboard/Dashboard';
import NotFoundComponent from '../components/not-found/NotFoundComponent';
import RoutedSearch from '../components/dashboard/RoutedSearch';

const index = () => {
  return (
    <Router>
      <AuthProvider>
        <AppProvider>
          <FormProvider>
            <Routes>
              <Route path='/' element={<Login title='Login' />} />
              <Route
                path='/forgot-password'
                element={<ForgotPassword title='Forgot-Password' />}
              />
              <Route
                path='/reset-password/:token'
                element={<ResetPassword title='Reset-Password' />}
              />
              <Route
                path='/dashboard/search'
                element={<RoutedSearch title='Search' />}
              />
              <Route
                path='/dashboard/search/:id'
                element={<RoutedSearch title='Search' />}
              />
              <Route
                path='/dashboard/help'
                element={<RoutedSearch title='Help' />}
              />
              <Route
                path='/dashboard'
                element={
                  <PrivateRoute>
                    <Dashboard title='Dashboard' />
                  </PrivateRoute>
                }
              />
              <Route path='*' element={<NotFoundComponent />} />
            </Routes>
          </FormProvider>
        </AppProvider>
      </AuthProvider>
    </Router>
  );
};

export default index;
