import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import appLogo from '../../assets/app-logo.svg';

const LoginForm = ({ formErrors, onSubmit }) => {
  const { loading } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const onFormSubmit = (e) => {
    e.preventDefault();
    onSubmit(email, password);
  };

  return (
    <div className=' w-full flex flex-col items-center'>
      <Link to='/'>
        <img
          src={appLogo}
          alt='app-logo'
          style={{ maxWidth: '200px', maxHeight: '200px' }}
        />
      </Link>
      <div className='mt-10 pt-2 pl-5 pr-5 pb-20 bg-white  form-container'>
        <p className='font-sans text-center  text-primary font-medium m-3 mb-5'>
          Quoqo offers the best contracting and <br />
          negotiation services for growing <br />
          businesses globally.
        </p>
        <form className='w-full' onSubmit={(e) => onFormSubmit(e)}>
          <div className='mb-3'>
            <label className='text-primary font-normal'>Email</label>
            <input
              type='text'
              placeholder='Enter email address'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className='w-full p-1 pl-3 focus:outline-none text-primary'
            />
            <p className='text-sm text-red-500'>{formErrors.email}</p>
          </div>
          <div className='mb-5'>
            <label className='text-primary font-normal'>Password</label>
            <input
              type='password'
              placeholder='******'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className='w-full p-1 pl-3 focus:outline-none text-primary'
            />
            <p className='text-sm text-red-500'>{formErrors.password}</p>
          </div>
          <button
            type='submit'
            className={`bg-primary ${loading && 'bg-gray-500'} hover:bg-secondary text-white p-1 pl-20 pr-20 font-medium w-full mb-3`}
            disabled={loading}
          >
            Login
          </button>
          <Link to='/forgot-password'>
            <p className='text-center text-red-600 font-normal flex justify-center'>
              <span>*</span>Forgot your password?
            </p>
          </Link>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
