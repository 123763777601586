import React, { useState } from 'react';
import Modal from 'react-modal';
import ReactTooltip from 'react-tooltip';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import './modal.css';
import DatePickerComponent from '../../shared/DatePickerComponent';
import useAxios from '../../hooks/useAxios';
import { resolve } from '../../api/resolve';
import HelpIcon from '../../assets/icons/Help-sm.svg';

const customStyles = {
  content: {
    top: '40%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    // minWidth: ,
    // maxHeight: '220px',
    borderRadius: '11px',
  },
  overlay: {
    backgroundColor: 'rgba(64, 8, 53, 0.63)',
  },
};

Modal.setAppElement('#root');
const ActivityDatePicker = ({ modalIsOpen, setIsOpen }) => {
  const api = useAxios();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const downloadLogs = async () => {
    if (startDate) {
      await resolve(
        api
          .post(
            `/contract/export_activity_logs/`,
            {
              from_date: dayjs(startDate).format('YYYY-MM-DD'),
              to_date: endDate
                ? dayjs(endDate).format('YYYY-MM-DD')
                : dayjs(startDate).format('YYYY-MM-DD'),
            },
            {
              responseType: 'blob',
            }
          )
          .then((res) =>
            saveAs(
              res.data,
              `Activity_${dayjs(new Date()).format('MM-DD-YYYY')}`
            )
          )
      );
      setStartDate('');
      setEndDate('');
      setIsOpen(false);
    }
  };
  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        onAfterClose={() => {}}
      >
        <div className='flex justify-center pt-3'>
          <h2 className='mb-2 text-primary font-bold text-sm'>
            Browse by Date
          </h2>
          <>
            <img
              src={HelpIcon}
              width={12}
              className='ml-1 -mt-2 cursor-pointer'
              data-tip='Select by date or date range'
            />
            <ReactTooltip backgroundColor='#400835' textColor='#ffffff' />
          </>
        </div>
        <ReactTooltip
          backgroundColor='#400835'
          textColor='#ffffff'
          className='z-50'
        />
        <DatePickerComponent
          selectsRange
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          inline
        />

        <div className='flex justify-center mt-5'>
          <button
            className='bg-primary  font-semibold text-white p-1 pl-8 pr-8 rounded-lg mr-5'
            onClick={(e) => {
              e.stopPropagation();
              downloadLogs();
            }}
          >
            Export
          </button>
          <button
            className='bg-[#606060] font-semibold text-white p-1 pl-8 pr-8 rounded-lg'
            onClick={(e) => setIsOpen(false)}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ActivityDatePicker;
