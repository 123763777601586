export const dropDownStyles = {
  indicatorSeparator: () => false,
  control: (provided, state) => {
    return {
      ...provided,
      background: state.isDisabled ? '#d4c390' : '#f6d87a',
      outline: 'none',
      boxShadow: 'none',
      border: '1px solid #400835',
      cursor: 'pointer',
      '&:hover': {
        border: '1px solid #400835',
        boxShadow: 'none',
      },
    };
  },
  dropdownIndicator: (provided) => {
    return {
      ...provided,
      color: '#400835',
      '&:hover': { color: '#400835' },
    };
  },
  placeholder: (provided) => {
    return {
      ...provided,
      color: '#400835',
      fontSize: 16,
      fontWeight: 'bold',
    };
  },
  menuList: (provided) => ({ ...provided, height: 200, background: '#f6d87a' }),
  input: (provided) => {
    return {
      ...provided,
      color: '#400835',
    };
  },
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#fff' : '#400835',
    fontSize: 16,
    fontWeight: '400',
    background: '#f6d87a',
    cursor: 'pointer',
    '&:hover': {
      background: '#F6C107',
      fontWeight: 'bold',
    },
  }),
  singleValue: (provided) => {
    return {
      ...provided,
      color: '#400835',
      fontWeight: 'bold',
    };
  },
};

export const customStyles = {
  indicatorSeparator: () => false,
  control: (provided, state) => {
    return {
      ...provided,
      outline: 'none',
      boxShadow: 'none',
      border: '1px solid #400835',
      borderRadius: '10px',
      cursor: 'pointer',
      '&:hover': {
        border: '1px solid #400835',
        boxShadow: 'none',
      },
    };
  },
  dropdownIndicator: (provided) => {
    return {
      ...provided,
      color: 'transparent',
      '&:hover': { color: 'transparent' },
    };
  },
  placeholder: (provided) => {
    return {
      ...provided,
      // color: '#400835',
    };
  },
  menuList: (provided) => ({ ...provided, height: 200 }),
  input: (provided) => {
    return {
      ...provided,
      color: '#400835',
    };
  },
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#fff' : '#400835',
    fontSize: 16,
    fontWeight: '400',
    background: state.isSelected ? '#400835' : undefined,
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(64, 8, 53, 0.63)',
      color: '#fff',
      fontWeight: 'bold',
    },
  }),
  singleValue: (provided) => {
    return {
      ...provided,
      color: '#400835',
    };
  },
};
