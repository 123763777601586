import React, { useEffect, useState, useContext } from 'react';
import Select from 'react-select';
import dayjs from 'dayjs';
import AppContext from '../../context/AppContext';
import LoadingScreen from '../loader/LoadingScreen';
import Search from '../../assets/icons/Search.svg';
import CloseIcon from '../../assets/icons/Close.svg';
import { dropDownStyles } from '../../constants/styles';
import AdminTable from './AdminTable';
import { adminDashbaoradTableColumns } from '../../constants/constants';
import useAxios from '../../hooks/useAxios';
import { resolve } from '../../api/resolve';

const AdminClauses = () => {
  const api = useAxios();
  const {
    setContractTypes,
    contractTypes,
    selectedContractType,
    setSelectedContractType,
    reviewSelection,
    setReviewSelection,
  } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [errMsg, setErrMsg] = useState('');
  const [searchText, setSearchText] = useState('');
  const [nextPage, setIsNextPage] = useState(null);
  const [prevPage, setIsPrevPage] = useState(null);
  const [pageLimit, setPageLimit] = useState(5);
  const [contractCount, setContractCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortingOrder, setSortingOrder] = useState('desc');
  const [sortColumn, setSortColumn] = useState('-created_at');
  const [contracts, setContracts] = useState([]);
  const [contractTypesList, setContractsTypesList] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [showClearIcon, setShowClearIcon] = useState(false);

  const getTypesData = async () => {
    const types = await resolve(
      api.get('/contract/get_all_contract_types/').then((res) => res.data)
    );
    if (types.data) {
      const filteredArr = [];
      const { contract_type_list } = types.data;
      contract_type_list.forEach((type) => {
        if (type && type.name.length > 1)
          filteredArr.push({ value: type.name, label: type.name, id: type.id });
      });
      setContractTypes(filteredArr);
      setSelectedContractType(filteredArr[0]);
    }
    setLoading(false);
  };
  const getContracts = async (clearText) => {
    const res = await resolve(
      api
        .get(
          `/reviewer/get_contracts/${selectedContractType.value}?search=${
            clearText ? '' : searchText
          }&ordering=${sortColumn}&page=${currentPage}&page_size=${pageLimit}`
        )
        .then((res) => res.data)
    );

    if (res.data) {
      const { results, next, previous, count } = res.data;
      const filterData = [];
      if (results.length > 0) {
        setContractCount(count);
        results.forEach((result) => {
          const contractObj = {
            name: result.name || result.doc_name,
            created_at: dayjs(result.created_at).format('DD MMMM YYYY'),
            tags: result.tags,
            type: result.type,
            updated_at: dayjs(result.updated_at).format('D MMMM YYYY'),
            renewal_date: result.renewal_date
              ? dayjs(result.renewal_date).format('DD MMMM YYYY')
              : 'Not Added',
            company_id: result.company_id,
            id: result.id,
            converted_file_url: result.converted_file_url,
            file_url: result.file_url,
            doc_name: result.doc_name,
            reminder_days: result.remainder_days,
            reminder_date: result.remainder_date,
            uploaded_by: result.uploaded_by,
          };
          filterData.push(contractObj);
        });
      } else {
        if (currentPage === 1) setErrMsg('No contracts added yet');
        else setErrMsg('No more contracts to view');
      }
      setContracts([...filterData]);
      setReviewSelection({
        ...reviewSelection,
        file_url: filterData.length > 0 && filterData[0].file_url,
        doc_name: filterData.length > 0 && filterData[0].doc_name,
        converted_file_url:
          filterData.length > 0 && filterData[0].converted_file_url,
        id: filterData.length > 0 && filterData[0].id,
      });
      setIsNextPage(next);
      setIsPrevPage(previous);
    } else {
      setIsNextPage(null);
    }
    setPageLoading(false);
  };
  useEffect(() => {
    getTypesData();
    return () => setContracts([]);
  }, []);

  useEffect(() => {
    if (contractTypes.length > 0 && selectedContractType) {
      getContracts();
    }
  }, [contractTypes, selectedContractType, sortColumn, pageLimit, currentPage]);

  if (loading) {
    return <LoadingScreen />;
  }
  return (
    <div className='text-primary'>
      <h1 className='text-4xl font-bold'>Contract Types</h1>
      <form>
        <div className='flex mt-3'>
          <Select
            options={contractTypes}
            placeholder='Contract Type'
            styles={dropDownStyles}
            isSearchable={true}
            className='w-3/12 text-primary'
            onChange={(repo) => {
              setSelectedContractType(repo);
            }}
            defaultValue={contractTypes[0]}
          />
          <div className='flex justify-between items-center border-2 border-primary rounded-md rounded-tl-lg rounded-bl-lg bg-inputBackground px-2 mr-2 w-1/3 ml-2'>
            <input
              placeholder='Enter Contract Name here'
              className='px-1 py-1 focus:outline-none bg-transparent w-11/12'
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                setShowClearIcon(true);
                if (!e.target.value.trim()) {
                  setShowClearIcon(false);
                }
              }}
            />
            {showClearIcon && (
              <img
                src={CloseIcon}
                width={14}
                className='cursor-pointer'
                onClick={() => {
                  setSearchText('');
                  getContracts(true);
                  setShowClearIcon(false);
                }}
              />
            )}
          </div>
          <button
            type='submit'
            className='flex bg-primary text-white  px-4 rounded-lg items-center justify-between'
            onClick={(e) => {
              e.preventDefault();
              getContracts();
            }}
          >
            <img src={Search} style={{ width: '1rem' }} />
            <span className='font-semibold ml-1'>Search</span>
          </button>
        </div>
      </form>
      <div className='mt-3 rounded-lg'>
        {contracts.length < 1 && (
          <p className='text-red-500 text-sm font-medium'>{errMsg}</p>
        )}
      </div>
      <AdminTable
        contractTableColumns={adminDashbaoradTableColumns}
        contractsData={contracts}
        nextPage={nextPage}
        prevPage={prevPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        sortingOrder={sortingOrder}
        setSortingOrder={setSortingOrder}
        setSortColumn={setSortColumn}
        pageLimit={pageLimit}
        setPageLimit={setPageLimit}
        contractCount={contractCount}
        setContractCount={setContractCount}
        showActionBtn
        showPageLimit={true}
      />
    </div>
  );
};

export default AdminClauses;
