import React, { useContext, useEffect, useState } from 'react';
import './homepage.css';
import PieChart from '../demographics/PieChart';
import StackedBarChart from '../demographics/StackedBarChart';
import CircularProgress from '../demographics/CircularProgress';
import AuthContext from '../../context/AuthContext';
import ActivityLog from '../demographics/ActivityLog';

const HomePage = () => {
  const { user } = useContext(AuthContext);
  const [isReviewer, setisReviewer] = useState(user.user_type === 'reviewer');
  useEffect(() => {
    let hubspot = document.querySelector('#hubspot-messages-iframe-container');
    if (hubspot) {
      hubspot.style.visibility = 'hidden';
    }
    return () => {
      if (hubspot) {
        hubspot.style.visibility = 'visible';
      }
    };
  });
  return (
    <div className='demographics-container'>
      <h1 className='text-primary text-4xl font-bold'>Welcome to Quoqo</h1>
      {isReviewer ? (
        <StackedBarChart />
      ) : (
        <>
          {user.user_type === 'super_admin' ? (
            <>
              <div className='grid grid-cols-2 gap-x-4'>
                <CircularProgress user={user} />
                <PieChart />
              </div>
              <div className='grid grid-cols-2 gap-x-4'>
                <StackedBarChart />
                <ActivityLog />
              </div>
            </>
          ) : user.user_type == 'admin' ? (
            <>
              <div className='grid grid-cols-2 gap-x-4'>
                <CircularProgress user={user} />
                <ActivityLog />
              </div>
              <StackedBarChart />
            </>
          ) : (
            <>
              <div className='grid grid-cols-2 gap-x-4'>
                <CircularProgress user={user} />
              </div>
              <StackedBarChart />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default HomePage;
