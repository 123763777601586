import React, { useState, useContext } from 'react';
import Modal from 'react-modal';
import { Helmet } from 'react-helmet';
import './modal.css';
import useAxios from '../../hooks/useAxios';
import { resolve } from '../../api/resolve';
import CloseIcon from '../../assets/icons/Close.svg';
import AuthContext from '../../context/AuthContext';

const customStyles = {
  content: {
    top: '40%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 450,
    borderRadius: '11px',
  },
  overlay: {
    backgroundColor: 'rgba(64, 8, 53, 0.63)',
  },
};
const iframeStyles = {
  content: {
    top: '2%',
    bottom: '2%',
    borderRadius: '11px',
  },
  overlay: {
    backgroundColor: 'rgba(64, 8, 53, 0.63)',
  },
};

Modal.setAppElement('#root');
const HelpModuleModal = ({
  modalIsOpen,
  setIsOpen,
  isSupportRequest,
  setIsSupportRequest,
}) => {
  const api = useAxios();
  const { user } = useContext(AuthContext);
  const [response, setResponse] = useState({
    isError: false,
    msg: '',
    isSuccess: false,
  });
  const [description, setDescription] = useState('');

  const sendSupportRequest = async () => {
    if (description.trim().length > 0) {
      const res = await resolve(
        api
          .post(
            `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.REACT_APP_HUBSPOT_PORTAL_ID}/${process.env.REACT_APP_HUBSPOT_FORM_ID}`,
            JSON.stringify({
              fields: [
                {
                  objectTypeId: '0-1',
                  name: 'email',
                  value: user.user_email,
                },
                {
                  objectTypeId: '0-1',
                  name: 'firstname',
                  value: user.name,
                },
                {
                  objectTypeId: '0-1',
                  name: 'TICKET.content',
                  value: description,
                },
              ],
            }),
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )
          .then((res) => res.data)
      );
      if (res.data) {
        setResponse({
          ...response,
          isSuccess: true,
          msg: 'Your request has been received and is being reviewed',
        });
      }
      if (res.error) {
        setResponse({
          ...response,
          isError: true,
          msg: 'Unable to submit request. Try again',
        });
      }
    }
  };
  return (
    <div className=''>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        style={isSupportRequest ? customStyles : iframeStyles}
        contentLabel='Example Modal'
        onAfterClose={() => {
          setIsSupportRequest(false);
          setResponse({ isError: false, msg: '', isSuccess: false });
          setDescription('');
        }}
      >
        {isSupportRequest ? (
          <>
            <h2 className='text-primary font-bold text-lg text-center'>
              Support Request
            </h2>
            {!response.isError && !response.isSuccess ? (
              <form
                className='mt-3'
                onSubmit={(e) => {
                  e.preventDefault();
                  sendSupportRequest();
                }}
              >
                <textarea
                  className='w-full border-primary text-primary focus:outline-none p-1'
                  style={{ border: '1px solid #400835', borderRadius: 10 }}
                  rows={5}
                  placeholder='Enter details here'
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                />
                <button
                  type='submit'
                  className='bg-primary w-full text-white font-semibold rounded-lg p-1 mt-2'
                >
                  Send
                </button>
              </form>
            ) : (
              <>
                <p className='text-primary font-normal text-md my-3 text-center'>
                  {response.msg}
                </p>
                <button
                  onClick={() => {
                    if (response.isSuccess) {
                      setIsOpen(false);
                    }
                    setResponse({
                      ...response,
                      isError: false,
                      isSuccess: false,
                    });
                  }}
                  className='bg-primary w-2/3 mx-auto block text-white font-semibold rounded-lg p-1'
                >
                  Accept
                </button>
              </>
            )}
          </>
        ) : (
          <>
            <div
              class='meetings-iframe-container'
              data-src='https://meetings.hubspot.com/quoqo?embed=true'
            >
              <div className='flex justify-end'>
                <img
                  className='cursor-pointer fixed'
                  onClick={() => setIsOpen(false)}
                  src={CloseIcon}
                />
              </div>
            </div>
            <Helmet>
              <script
                type='text/javascript'
                src='https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js'
              ></script>
            </Helmet>

            {/* <iframe
              src='https://meetings.hubspot.com/quoqo'
              style={{ width: '100%', height: '100%' }}
            ></iframe> */}
          </>
        )}
      </Modal>
    </div>
  );
};

export default HelpModuleModal;
