import React, { useEffect, useState, useContext } from 'react';
import Select from 'react-select';
import AuthContext from '../../context/AuthContext';
import AppContext from '../../context/AppContext';
import Search from '../../assets/icons/Search.svg';
import CloseIcon from '../../assets/icons/Close.svg';
import AddButton from '../../shared/AddButton';
import Table from '../../shared/Table';
import './commonComponent.css';

const customStyles = {
  indicatorSeparator: () => false,
  control: (provided, state) => {
    return {
      ...provided,
      background: '#f6d87a',
      outline: 'none',
      boxShadow: 'none',
      border: '1px solid #400835',
      cursor: 'pointer',
      '&:hover': {
        border: '1px solid #400835',
        boxShadow: 'none',
      },
    };
  },
  dropdownIndicator: (provided) => {
    return {
      ...provided,
      color: '#400835',
      '&:hover': { color: '#400835' },
    };
  },
  placeholder: (provided) => {
    return {
      ...provided,
      color: '#400835',
      fontSize: 16,
      fontWeight: 'bold',
    };
  },
  menuList: (provided) => ({ ...provided, height: 200 }),
  input: (provided) => {
    return {
      ...provided,
      color: '#400835',
    };
  },
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#fff' : '#400835',
    fontSize: 16,
    fontWeight: '400',
    background: state.isSelected ? '#400835' : undefined,
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(64, 8, 53, 0.63)',
      color: '#fff',
      fontWeight: 'bold',
    },
  }),
  singleValue: (provided) => {
    return {
      ...provided,
      color: '#400835',
      fontWeight: 'bold',
    };
  },
};

const CommonModuleUI = ({
  moduleTableColumns,
  moduleData,
  prevPage,
  nextPage,
  heading,
  isCompany,
  inputPlaceholder,
  addBtnText,
  errMsg,
  currentPage,
  setCurrentPage,
  getSearchData,
  companiesList,
  activeLinkTitle,
  sortingOrder,
  setSortingOrder,
  setSortColumn,
  getModuleData,
}) => {
  const { user } = useContext(AuthContext);
  const { activeLink } = useContext(AppContext);
  const [searchText, setSearchText] = useState('');
  const [companyID, setCompanyID] = useState(null);
  const [showClearIcon, setShowClearIcon] = useState(false);
  return (
    <div className='text-primary'>
      <h1 className='text-4xl font-bold'>{heading}</h1>
      {activeLink !== 'Reviewers' && (
        <form>
          <div className='flex mt-3'>
            {!isCompany && (
              <>
                {user.user_type === 'super_admin' && (
                  <Select
                    options={companiesList}
                    placeholder='Companies List'
                    styles={customStyles}
                    isSearchable={true}
                    onChange={(company) => setCompanyID(company.id)}
                    className='w-3/12 text-primary'
                  />
                )}

                <div
                  className={`flex justify-between items-center border-2 border-primary rounded-md rounded-tl-lg rounded-bl-lg bg-inputBackground pl-1 mx-2 w-3/12 `}
                >
                  <input
                    placeholder={inputPlaceholder}
                    className=' py-1 focus:outline-none bg-transparent w-4/5 pr-1'
                    onChange={(e) => {
                      setSearchText(e.target.value);
                      setShowClearIcon(true);
                      if (!e.target.value.trim()) {
                        setShowClearIcon(false);
                      }
                    }}
                    value={searchText}
                  />
                  {showClearIcon && (
                    <img
                      src={CloseIcon}
                      width={14}
                      className='cursor-pointer mr-3'
                      onClick={() => {
                        setSearchText('');
                        getModuleData();
                        setShowClearIcon(false);
                      }}
                    />
                  )}
                </div>
                <button
                  type='submit'
                  className='flex bg-primary text-white  px-4 rounded-lg items-center justify-between'
                  onClick={(e) => {
                    e.preventDefault();
                    getSearchData(searchText, companyID);
                  }}
                >
                  <img src={Search} style={{ width: '1rem' }} />
                  <span className='font-semibold ml-1'>Search</span>
                </button>
              </>
            )}
            {isCompany && (
              <>
                <div className=' flex justify-between items-center border-2 border-primary rounded-md rounded-tl-lg rounded-bl-lg bg-inputBackground pl-1 mx-2 w-3/12'>
                  <input
                    placeholder='Enter company name here'
                    className='px-1 py-1 focus:outline-none bg-transparent w-4/5'
                    onChange={(e) => {
                      setSearchText(e.target.value);
                      setShowClearIcon(true);
                      if (!e.target.value.trim()) {
                        setShowClearIcon(false);
                      }
                    }}
                    value={searchText}
                  />
                  {showClearIcon && (
                    <img
                      src={CloseIcon}
                      width={14}
                      className='cursor-pointer mr-3'
                      onClick={() => {
                        setSearchText('');
                        getModuleData();
                        setShowClearIcon(false);
                      }}
                    />
                  )}
                </div>
                <button
                  type='submit'
                  className='flex bg-primary text-white  px-4 rounded-lg items-center justify-between'
                  onClick={(e) => {
                    e.preventDefault();
                    getSearchData(searchText);
                  }}
                >
                  <img src={Search} style={{ width: '1rem' }} />
                  <span className='font-semibold ml-1'>Search</span>
                </button>
              </>
            )}
          </div>
        </form>
      )}
      <div className='flex justify-end mt-3'>
        <AddButton text={addBtnText} currentForm={heading} />
      </div>
      <div className='mt-5 rounded-lg'>
        {moduleData.length < 1 && (
          <p className='text-red-500 text-sm font-medium'>{errMsg}</p>
        )}
        <Table
          moduleTableColumns={moduleTableColumns}
          moduleData={moduleData}
          nextPage={nextPage}
          prevPage={prevPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          heading={heading}
          activeLinkTitle={activeLinkTitle}
          sortingOrder={sortingOrder}
          setSortingOrder={setSortingOrder}
          setSortColumn={setSortColumn}
        />
      </div>
    </div>
  );
};

export default CommonModuleUI;
