import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import CommonModule from './CommonComponent';
import useAxios from '../../hooks/useAxios';
import { resolve } from '../../api/resolve';
import { adminTableColumns } from '../../constants/constants';

const Admins = () => {
  const api = useAxios();
  const [admins, setAdmins] = useState([]);
  const [nextPage, setIsNextPage] = useState(null);
  const [prevPage, setIsPrevPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [errMsg, setErrMsg] = useState('');
  const [pageLoading, setPageLoading] = useState(true);
  const [sortingOrder, setSortingOrder] = useState('desc');
  const [sortColumn, setSortColumn] = useState('created_at');
  const getAdmins = async () => {
    const res = await resolve(
      api
        .get(`user/admin/view/?ordering=${sortColumn}&page=${currentPage}`)
        .then((res) => res.data)
    );
    if (res.data) {
      const { results, next, previous } = res.data;
      const filterData = [];
      if (results.length > 0) {
        results.forEach((result) => {
          const userObj = {
            first_name: result.first_name,
            dateCreated: dayjs(result.created_at).format('D MMMM YYYY'),
            status: result.is_active_user ? 'Active' : 'In-Active',
            email: result.email,
            confirm_email: result.email,
            is_active_user: result.is_active_user,
            phone: result.phone,
            company_id: result.company_id,
            company_name: result.company_name,
            id: result.id,
          };
          filterData.push(userObj);
        });
      } else {
        setErrMsg('No admins added yet');
      }
      setAdmins([...filterData]);
      setIsNextPage(next);
      setIsPrevPage(previous);
    }
    setPageLoading(false);
  };

  const searchAdmin = async (searchText, companyID) => {
    if (searchText.trim().length > 0) {
      const res = await resolve(
        api
          .get(
            `user/admin/view/?company_id=${
              companyID ? companyID : ''
            }&search=${searchText}`
          )
          .then((res) => res.data)
      );
      if (res.data) {
        const { results, next, previous } = res.data;
        const filterData = [];
        if (results.length > 0) {
          results.forEach((result) => {
            const userObj = {
              first_name: result.first_name,
              dateCreated: dayjs(result.created_at).format('D MMMM YYYY'),
              status: result.is_active_user ? 'Active' : 'In-Active',
              email: result.email,
              confirm_email: result.email,
              is_active_user: result.is_active_user,
              phone: result.phone,
              company_id: result.company_id,
              company_name: result.company_name,
              id: result.id,
            };
            filterData.push(userObj);
          });
        } else {
          setErrMsg('No admin found');
        }
        setAdmins([...filterData]);
        setIsNextPage(next);
        setIsPrevPage(previous);
      }
    } else {
      getAdmins();
    }
  };
  useEffect(() => {
    getAdmins();
  }, [currentPage, sortColumn]);
  return (
    <CommonModule
      heading='Admins'
      inputPlaceholder='Enter admin name here'
      addBtnText='Add New Admin'
      errMsg={errMsg}
      moduleData={admins}
      moduleTableColumns={adminTableColumns}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      isCompany={false}
      nextPage={nextPage}
      prevPage={prevPage}
      getSearchData={searchAdmin}
      activeLinkTitle='AdminForm'
      pageLoading={pageLoading}
      sortingOrder={sortingOrder}
      setSortingOrder={setSortingOrder}
      setSortColumn={setSortColumn}
      getModuleData={getAdmins}
    />
  );
};

export default Admins;
