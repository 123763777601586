import React from 'react';
import { ToastContainer } from 'react-toastify';
import TagManager from 'react-gtm-module';
import 'react-toastify/dist/ReactToastify.css';
import Routes from './routes';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG,
};
if (process.env.REACT_APP_ENV === 'PRODUCTION') {
  TagManager.initialize(tagManagerArgs);
}
const App = () => {
  return (
    <>
      <ToastContainer />
      <Routes />;
    </>
  );
};

export default App;
