import React, { useEffect } from 'react';
import ForgotPasswordForm from './ForgotPasswordForm';
import { appName } from '../../constants/constants';
import './forgotpassword.css';

const ForgotPasswordUI = ({ title, formErrors, onSubmit }) => {
  useEffect(() => {
    document.title = `${appName} | ${title}`;
  });
  return (
    <div className='flex w-full h-screen forgot-password-container'>
      <div className='w-1/3 bg-[#F5F2FA] forgot-password-form-container'>
        <ForgotPasswordForm formErrors={formErrors} onSubmit={onSubmit} />
      </div>
      <div className='w-2/3  forgot-password-content-container text-primary '>
        <div className='flex'>
          <h1 className='font-bold text-4xl mr-16'>Quoqo Legacy</h1>
          <button className='bg-secondary ml-16 text-white p-1 pl-20 pr-20 font-medium'>
            <a
              href='https://launch.quoqo.com/meetings/chetan12/quoqo-platform-demo-60-minutes'
              target='_blank'
            >
              Request a demo
            </a>
          </button>
        </div>
        <p className='font-normal mt-8'>
          Efficiently manage and store your legacy contracts with Quoqo Legacy.
        </p>
        <p className='font-normal mt-3'>
          Keep your contracts stored securely in our intelligent AI repository
          with automatic indexing, searching, extraction and analytics -- all
          built in!
        </p>
        <h1 className='mt-3 text-2xl font-bold'>
          Automatic extraction of key data
        </h1>
        <p className='mt-2 text-medium'>
          Quoqo’s proprietary AI automatically pulls out hundreds of key clauses
          from your contracts providing quick insight into your legacy
          contracts.
        </p>
        <h1 className='mt-3 text-2xl font-bold'>
          Never miss a critical deadline
        </h1>
        <p className='mt-2 text-medium'>
          What’s more, add custom notifications for critical contract dates and
          obligations, so that you never miss a critical deadline
        </p>
        <h1 className='mt-3 text-2xl font-bold'>
          Role based access and sharing
        </h1>
        <p className='mt-2 text-medium'>
          Restrict repository and account access based on a user role within an
          organization.
        </p>
      </div>
    </div>
  );
};

export default ForgotPasswordUI;
