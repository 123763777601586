import React from 'react';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const DatePickerComponent = ({
  startDate,
  setStartDate,
  placeholderText,
  inline,
  endDate,
  setEndDate,
  selectsRange,
}) => {
  return (
    <DatePicker
      selected={startDate}
      startDate={startDate && startDate}
      endDate={endDate && endDate}
      onChange={(date) => {
        if (selectsRange) {
          const [start, end] = date;
          setStartDate(start);
          setEndDate(end);
        } else {
          setStartDate(date);
        }
      }}
      className='border-2 border-primary rounded-lg p-1 w-full outline-none'
      showPopperArrow={false}
      inline={inline}
      selectsRange={selectsRange}
      placeholderText={placeholderText}
      formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
      minDate={!selectsRange && new Date()}
      renderCustomHeader={({
        date,
        decreaseMonth,
        increaseMonth,
        increaseYear,
        decreaseYear,
      }) => (
        <div className='custom-header'>
          <div className='flex items-center w-2/5 justify-between font-normal cursor-pointer text-primary text-base'>
            <span
              onClick={(e) => {
                e.preventDefault();
                decreaseMonth();
              }}
              className='text-xl'
            >
              {'<'}
            </span>
            <span>{months[date.getMonth()]}</span>
            <span
              onClick={(e) => {
                e.preventDefault();
                increaseMonth();
              }}
              className='text-xl'
            >
              {'>'}
            </span>
          </div>
          <div className='flex items-center w-2/5 justify-between font-normal cursor-pointer text-primary text-base'>
            <span
              onClick={(e) => {
                e.preventDefault();
                decreaseYear();
              }}
              className='text-xl'
            >
              {'<'}
            </span>
            <span>{date.getFullYear()}</span>
            <span
              onClick={(e) => {
                e.preventDefault();
                increaseYear();
              }}
              className='text-xl'
            >
              {'>'}
            </span>
          </div>
        </div>
      )}
    />
  );
};

export default DatePickerComponent;
